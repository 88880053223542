import React from "react";
import styles from "./FlashcardButton.module.css";

const CorrectIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round">
        <circle cx="12" cy="12" r="10"></circle>
        <path d="m9 12 2 2 4-4"></path>
    </svg>
);

const IncorrectIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round">
        <circle cx="12" cy="12" r="10"></circle>
        <path d="M8 12h8"></path>
    </svg>
);

const UncoverIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round">
        <path d="M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0"></path>
        <circle cx="12" cy="12" r="3"></circle>
    </svg>
);

const RestartIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M9.03586 8.54361L5.31815 8.54361L5.31815 4.8259"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.1381 13.7113C5.54862 15.2434 6.4673 16.5906 7.74363 17.5323C9.01997 18.474 10.5783 18.9543 12.1633 18.8946C13.7483 18.8348 15.266 18.2385 16.4678 17.2034C17.6696 16.1683 18.4843 14.7557 18.7783 13.197C19.0723 11.6384 18.8282 10.0261 18.0861 8.62428C17.3439 7.22248 16.1477 6.11428 14.6934 5.48123C13.239 4.84818 11.6128 4.72781 10.0812 5.13985C8.54947 5.55189 7.20313 6.4719 6.26269 7.74917"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const NextIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round">
        <circle cx="12" cy="12" r="10"></circle>
        <path d="M8 12h8"></path>
        <path d="m12 16 4-4-4-4"></path>
    </svg>
);

const FlashCardButton = ({ type, onClick }) => {
    let content = "";
    let icon = "";
    switch (type) {
        case "correct":
            content = "";
            icon = CorrectIcon;
            break;
        case "incorrect":
            content = "";
            icon = IncorrectIcon;
            break;
        case "next-correct":
        case "next-incorrect":
            content = "";
            icon = NextIcon;
            break;
        case "restart":
            content = "Restart";
            icon = RestartIcon;
            break;
        case "uncover":
            content = "Uncover";
            icon = UncoverIcon;
            break;
        default:
            content = "";
            icon = "";
            break;
    }
    return (
        <button className={styles.btn + " " + styles[type]} onClick={onClick && onClick}>
            {icon} {content}
        </button>
    );
};
export default FlashCardButton;
