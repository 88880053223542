import styles from "./toolbar-bottom.module.css";

import Icon from "../Icon";
import IconFlashCards from "./IconFlashCards";
import IconPdf from "./IconPdf";
import IconSummary from "./IconSummary";
import IconTutor from "./IconTutor";

export default function ToolbarBottom({
    isPdfViewerVisible,
    onClickFileManager,
    onTogglePdfViewer,
    currentAppMode,
    onAppModeChange,
}) {
    return (
        <div className={styles.menu}>
            <div className={styles.menuBorder} />
            <ul className={styles.menuItems}>
                <li onClick={onTogglePdfViewer} className={isPdfViewerVisible ? styles.active : ""}>
                    <span className={styles.image}>
                        <IconPdf />
                    </span>
                    <span className={styles.description}>Document</span>
                </li>
                <li
                    className={
                        currentAppMode === "tutor" && !isPdfViewerVisible ? styles.active : ""
                    }
                    onClick={() => {
                        if (isPdfViewerVisible) {
                            onTogglePdfViewer();
                        }
                        onAppModeChange && onAppModeChange("tutor");
                    }}>
                    <span className={styles.image}>
                        <IconTutor />
                    </span>
                    <span className={styles.description}>Tutor</span>
                </li>
                <li
                    className={
                        currentAppMode === "summary" && !isPdfViewerVisible ? styles.active : ""
                    }
                    onClick={() => {
                        if (isPdfViewerVisible) {
                            onTogglePdfViewer();
                        }
                        onAppModeChange && onAppModeChange("summary");
                    }}>
                    <span className={styles.image}>
                        <IconSummary />
                    </span>
                    <span className={styles.description}>Summary</span>
                </li>
                <li
                    className={
                        currentAppMode === "cards" && !isPdfViewerVisible ? styles.active : ""
                    }
                    onClick={() => {
                        if (isPdfViewerVisible) {
                            onTogglePdfViewer();
                        }
                        onAppModeChange && onAppModeChange("cards");
                    }}>
                    <span className={styles.image}>
                        <IconFlashCards />
                    </span>
                    <span className={styles.description}>Flashcards</span>
                </li>
                <li onClick={onClickFileManager}>
                    <span className={styles.image}>
                        <Icon name="FolderIcon" />
                    </span>
                    <span className={styles.description}>Files</span>
                </li>
            </ul>
        </div>
    );
}
