const BASE_API_ADDRESS = process.env.REACT_APP_API_GATEWAY_REST;
const BASE_WSS_ADDRESS = process.env.REACT_APP_API_GATEWAY_WS;

export const config = {
    env: process.env.NODE_ENV,
    base_api_address: BASE_API_ADDRESS,
    base_wss_address: BASE_WSS_ADDRESS,

    // posthog
    posthog_enabled: process.env.REACT_APP_POSTHOG_ENABLED,
    posthog_api_key: process.env.REACT_APP_POSTHOG_API_KEY,
    posthog_api_host: process.env.REACT_APP_POSTHOG_API_HOST,

    memberstack: {
        publicKey: process.env.REACT_APP_MEMBERSTACK_PUBLIC_KEY,
    },

    api_urls: {
        add_document_to_user: BASE_API_ADDRESS + "/add_document_to_user",
        add_to_database: BASE_API_ADDRESS + "/add_document_to_database",
        clear_chat: BASE_API_ADDRESS + "/delete_chat",
        export_flashcards: BASE_API_ADDRESS + "/export_to_anki",
        get_chat: BASE_API_ADDRESS + "/get_chat",
        get_county_from_ip: BASE_API_ADDRESS + "/get_country_from_ip_address",
        get_filemanager: BASE_API_ADDRESS + "/get_filemanager",
        get_flashcards: BASE_API_ADDRESS + "/get_flashcards",
        get_flashcards_title: BASE_API_ADDRESS + "/get_flashcards_title",
        get_pdf_url: BASE_API_ADDRESS + "/get_pdf_url",
        get_summary: BASE_API_ADDRESS + "/get_summary",
        get_summary_title: BASE_API_ADDRESS + "/get_summary_title",
        save_flashcards: BASE_API_ADDRESS + "/generate_upload_link_flashcards",
        save_flashcards_title: BASE_API_ADDRESS + "/save_flashcards_title",
        save_summary_title: BASE_API_ADDRESS + "/save_summary_title",
        set_default_language: BASE_API_ADDRESS + "/set_default_language",
        submit_feedback: BASE_API_ADDRESS + "/submit_feedback",
        submit_support_request: BASE_API_ADDRESS + "/submit_support_request",
        update_filemanager: BASE_API_ADDRESS + "/add_dates_to_filemanager",
        upload_feedback_as_json: BASE_API_ADDRESS + "/generate_upload_link_feedback",
        upload_filesystem_as_json: BASE_API_ADDRESS + "/generate_upload_link_filemanager",
        upload_summary_as_json: BASE_API_ADDRESS + "/generate_upload_link_summary",
        upload_url: BASE_API_ADDRESS + "/generate_upload_link",
        wake_up_convert_to_pdf_url: BASE_API_ADDRESS + "/wake_up_convert_to_pdf",
        wake_up_document_processor_url: BASE_API_ADDRESS + "/wake_up_document_processor",
        wake_up_text_extract_url: BASE_API_ADDRESS + "/wake_up_text_extractor",
        wake_up_tutor_url: BASE_API_ADDRESS + "/wake_up_tutor",
    },
};
