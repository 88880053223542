import AddFolderIcon from "./AddFolderIcon";
import BurgerNavIcon from "./BurgerNavIcon";
import CheckIcon from "./CheckIcon";
import ChevronDownIcon from "./ChevronDownIcon";
import ChevronRightIcon from "./ChevronRightIcon";
import CopyIcon from "./CopyIcon";
import DiscIcon from "./DiskIcon";
import DownloadIcon from "./DownloadIcon";
import FileIcon from "./FileIcon";
import FolderIcon from "./FolderIcon";
import HistoryIcon from "./HistoryIcon";
import MagnifyingGlassIcon from "./MagnifyingGlassIcon";
import PencilIcon from "./PencilIcon";
import ProfileIcon from "./ProfileIcon";
import RepetitionIcon from "./RepetitionIcon";
import StudyModeIcon from "./StudyModeIcon";
import TrashIcon from "./TrashIcon";
import UploadIcon from "./UploadIcon";

const Icon = ({ name, width, height }) => {
    switch (name) {
        case "AddFolderIcon":
            return <AddFolderIcon width={width} height={height} />;
        case "BurgerNavIcon":
            return <BurgerNavIcon />;
        case "ChevronDownIcon":
            return <ChevronDownIcon />;
        case "ChevronRightIcon":
            return <ChevronRightIcon />;
        case "DiskIcon":
            return <DiscIcon />;
        case "FileIcon":
            return <FileIcon />;
        case "DownloadIcon":
            return <DownloadIcon />;
        case "UploadIcon":
            return <UploadIcon width={width} height={height} />;
        case "MagnifyingGlassIcon":
            return <MagnifyingGlassIcon width={width} height={height} />;
        case "TrashIcon":
            return <TrashIcon width={width} height={height} />;
        case "FolderIcon":
            return <FolderIcon />;
        case "ProfileIcon":
            return <ProfileIcon />;
        case "StudyModeIcon":
            return <StudyModeIcon />;
        case "HistoryIcon":
            return <HistoryIcon />;
        case "RepetitionIcon":
            return <RepetitionIcon />;
        case "CheckIcon":
            return <CheckIcon />;
        case "CopyIcon":
            return <CopyIcon />;
        case "PencilIcon":
            return <PencilIcon width={width} height={height} />;
        default:
            return "";
    }
};

export default Icon;
