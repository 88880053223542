import axios from "axios";
import { config } from "./config";

const wake_up_document_processor = async () => {
    try {
        console.log("waking up document processor");
        await axios.get(config.api_urls.wake_up_document_processor_url);
    } catch (error) {
        console.log(error);
        return null;
    }
};

const wake_up_tutor = async () => {
    try {
        console.log("waking up tutor");
        await axios.get(config.api_urls.wake_up_tutor_url);
    } catch (error) {
        console.log(error);
        return null;
    }
};

const wake_up_text_extract = async () => {
    try {
        console.log("waking up text extract");
        await axios.get(config.api_urls.wake_up_text_extract_url);
    } catch (error) {
        console.log(error);
        return null;
    }
};

const wake_up_convert_to_pdf = async () => {
    try {
        console.log("waking up convert to pdf");
        await axios.get(config.api_urls.wake_up_convert_to_pdf_url);
    } catch (error) {
        console.log(error);
        return null;
    }
};

export { wake_up_convert_to_pdf, wake_up_document_processor, wake_up_text_extract, wake_up_tutor };
