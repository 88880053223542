import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { capture_event, EVENTS } from "../../utils/analytics";
import { config } from "../../utils/config";
import Button from "../Button_v2/Button";
import FinishCard from "../Flashcard/FinishCard";
import Flashcard from "../Flashcard/Flashcard";
import InlineCardModal from "../InlineCardModal/InlineCardModal";
import ModeTitleBar from "../ModeTitleBar/ModeTitleBar";
import YesOrNoOverlayModal from "../OverlayModal/YesOrNoOverlayModal";
import buttonStyles from "./../Button_v2/Button_V2.module.css";
import FlashCardButton from "./FlashcardButton";
import styles from "./FlashcardContainer.module.css";

const initialValue = `<p>Content of your card</p>
<ul>
    <li>Example item in the list</li>
    <li>More examples</li>
</ul>`;

const QA_CARD_TEMPLATE = {
    id: -1,
    type: "FlashcardQaPairHtml",
    question: "",
    answer: initialValue,
    count_correct_answers: 0,
    count_incorrect_answers: 0,
};

const MULTIPLE_CHOICE_CARD_TEMPLATE = {
    id: -1,
    type: "FlashcardMultipleChoice",
    question: "",
    answers: [
        { is_correct: false, answer: "" },
        { is_correct: false, answer: "" },
        { is_correct: false, answer: "" },
        { is_correct: false, answer: "" },
    ],
    count_correct_answers: 0,
    count_incorrect_answers: 0,
};

const save_flashcards = async (
    document_id,
    user_id,
    flashcards,
    flashcardsLogs,
    flashcardStatus,
    uploadFileToS3
) => {
    try {
        const response = await axios.get(config.api_urls.save_flashcards, {
            params: {
                document_id: document_id,
                user_id: user_id,
            },
        });
        //console.log(response.data);
        const presignedUrl = response.data.presigned_url;
        //console.log(presignedUrl);

        const cards = {
            status: flashcardStatus,
            flashcards: flashcards,
            logs: flashcardsLogs,
        };

        const flashcardsJson = JSON.stringify(cards);
        const file = new Blob([flashcardsJson], { type: "application/json" });

        await uploadFileToS3(presignedUrl, file);
        console.log("Flashcards Saved");
    } catch (error) {
        console.log(error);
    }
};

const save_flashcards_title = async (document_id, flashcards_title) => {
    try {
        await axios.get(config.api_urls.save_flashcards_title, {
            params: {
                document_id: document_id,
                flashcards_title: flashcards_title,
            },
        });
        console.log("Flashcards Title Saved");
    } catch (error) {
        console.log(error);
    }
};

const AddCardContainer = ({ id, updatedModifyCardHandler }) => {
    const [decisionVisible, setDecisionVisible] = useState(false);

    return (
        <div className={styles.addCardContainer}>
            {decisionVisible && (
                <div className={styles.createCardButton}>
                    <Button
                        onClick={() => {
                            setDecisionVisible(false);
                            updatedModifyCardHandler(
                                "createCard",
                                MULTIPLE_CHOICE_CARD_TEMPLATE,
                                id
                            );
                        }}>
                        Multiple-Choice Card
                    </Button>
                </div>
            )}

            <div className={styles.button} onClick={() => setDecisionVisible(!decisionVisible)}>
                +
            </div>
            {decisionVisible && (
                <div className={styles.createCardButton}>
                    <Button
                        onClick={() => {
                            setDecisionVisible(false);
                            updatedModifyCardHandler("createCard", QA_CARD_TEMPLATE, id);
                        }}>
                        Q&A Card
                    </Button>
                </div>
            )}
        </div>
    );
};

const FlashcardContainer = ({
    document_id,
    user_id,
    uploadFileToS3,
    isStudyModeActive,
    numberOfRepetitions,
    flashcardLogs,
    setFlashCardLogs,
    isResetModalOpen,
    toggleResetModal,
    flashcards_title,
    setFlashCardsTitle,
    flashcards,
    getFlashcardsRefMap,
    setFlashCards,
    flashcardStatus,
    isPaidUser,
    toggleUpgradeModal,
    handleFlashcardCreation,
}) => {
    const [instructions, setInstructions] = useState([]);
    const [savingCardIds, setSavingCardIds] = useState([]);
    const [finishedSavingCardIds, setFinishedSavingCardIds] = useState([]);
    const [isFinished, setIsFinished] = useState(false);
    const [isShowingAnalytics, setIsShowingAnalytics] = useState(false);
    const [successCounts, setSuccessCounts] = useState({ 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 });
    const [didOneMove, setDidOneMove] = useState(false);

    const getSuccessCount = () => {
        let correctAnswers = 0;
        for (let index = 0; index < flashcards.length; index++) {
            correctAnswers += sortedAndShuffledFlashcards[index].count_correct_answers;
        }

        const targetRepetitions = numberOfRepetitions * flashcards.length;

        // console.log(correctAnswers, targetRepetitions);
        return ((correctAnswers / targetRepetitions) * 100).toFixed(2);
    };

    const shuffleArray = (array) => {
        return array.sort(() => Math.random() - 0.5);
    };

    // Sort flashcards by count_correct_answers and shuffle them
    const [sortedAndShuffledFlashcards, setSortedAndShuffledFlashcards] = useState(
        Object.values(
            flashcards.reduce((acc, card) => {
                acc[card.count_correct_answers] = acc[card.count_correct_answers] || [];
                acc[card.count_correct_answers].push(card);
                return acc;
            }, {})
        )
            .map((group) => shuffleArray(group))
            .flat()
    );

    const [currentStudyCard, setCurrentStudyCard] = useState(sortedAndShuffledFlashcards[0]);

    const updateInstructions = useCallback((actionType, cardDetails) => {
        setInstructions((prevInstructions) => [
            ...prevInstructions,
            { action: actionType, details: cardDetails },
        ]);
    }, []);

    const addNewLog = useCallback(() => {
        const currentDate = new Date();
        const dateString = currentDate.toLocaleDateString();
        const newLog = { mode: numberOfRepetitions, date: dateString };
        setFlashCardLogs((prevLogs) => [...prevLogs, newLog]);
        updateInstructions("addLog", { log: newLog });
    }, [numberOfRepetitions, updateInstructions, setFlashCardLogs]);

    useEffect(() => {
        setCurrentStudyCard(sortedAndShuffledFlashcards[0]);
    }, [sortedAndShuffledFlashcards]);

    useEffect(() => {
        const isFinished =
            sortedAndShuffledFlashcards[0].count_correct_answers >= numberOfRepetitions;

        setIsFinished(isFinished);
        if (isFinished) {
            console.log("flashcard-collection finished");
            addNewLog();

            capture_event(EVENTS.FLASHCARDS_COLLECTION_FINISHED, {
                numberOfRepetitions: numberOfRepetitions,
                document_id: document_id,
            });
        }
    }, [sortedAndShuffledFlashcards, numberOfRepetitions, addNewLog, setIsFinished, document_id]);

    useEffect(() => {
        let newCounts = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };

        for (let i = 0; i <= 5; i++) {
            newCounts[i] = sortedAndShuffledFlashcards.filter(
                (card) => card.count_correct_answers === i
            ).length;
        }

        //console.log(newCounts)
        setSuccessCounts(newCounts);
    }, [sortedAndShuffledFlashcards]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (instructions.length > 0) {
                //console.log(JSON.stringify(instructions));
                save_flashcards(
                    document_id,
                    user_id,
                    flashcards,
                    flashcardLogs,
                    flashcardStatus,
                    uploadFileToS3
                );
                setInstructions([]);
                //console.log(finishedSavingCardIds);
                setSavingCardIds([]);
                setTimeout(() => {
                    setFinishedSavingCardIds([]);
                }, 2000);
            }
        }, 2000);

        return () => clearInterval(interval); // Clear the interval when the component unmounts
    }, [
        document_id,
        flashcardLogs,
        flashcardStatus,
        flashcards,
        instructions,
        uploadFileToS3,
        user_id,
    ]);

    const onFlashcardsTitleChange = (title) => {
        setFlashCardsTitle(title);
        save_flashcards_title(document_id, title);
    };

    const toggleShowAnalytics = () => {
        setIsShowingAnalytics(!isShowingAnalytics);
    };

    const handleCardMarked = useCallback(
        (cardId, increment) => {
            console.log(
                "handleCardMarked triggered with cardId:",
                cardId,
                "and increment:",
                increment
            );
            const cardIndex = sortedAndShuffledFlashcards.findIndex((card) => card.id === cardId);
            // If the card is not found (index === -1), return here
            if (cardIndex === -1) return;

            const updatedCard = {
                ...sortedAndShuffledFlashcards[cardIndex],
                count_correct_answers:
                    sortedAndShuffledFlashcards[cardIndex].count_correct_answers + increment,
                count_incorrect_answers:
                    sortedAndShuffledFlashcards[cardIndex].count_incorrect_answers +
                    (1 - increment),
            };

            const newFlashcards = sortedAndShuffledFlashcards.filter(
                (_, index) => index !== cardIndex
            );

            const firstMatchingScoreIndex = newFlashcards.findIndex(
                (card) => card.count_correct_answers === updatedCard.count_correct_answers
            );

            const startIndex =
                firstMatchingScoreIndex === -1 && increment > 0
                    ? newFlashcards.length
                    : firstMatchingScoreIndex;
            const endIndex = newFlashcards.findIndex(
                (card) => card.count_correct_answers > updatedCard.count_correct_answers
            );
            const targetIndexRange = endIndex === -1 ? newFlashcards.length : endIndex;

            //console.log("Start Index:",startIndex);
            //console.log("End Index:", endIndex);
            //console.log("Target Range:", targetIndexRange);

            const fixedPosition = 0;
            // Calculate the absolute random position. For last two cards choose a random number between 0 and 1
            const randomPosition =
                startIndex === 0 && endIndex === 1
                    ? Math.random() < 0.5
                        ? 0
                        : 1
                    : startIndex + Math.floor(Math.random() * (targetIndexRange - startIndex));

            console.log("Final Index:", randomPosition);
            // Insert the card at the new position using ternary operator. If hit inccorect on last card to be learned show that card again
            newFlashcards.splice(
                startIndex === -1 && endIndex === 0 ? fixedPosition : randomPosition,
                0,
                updatedCard
            );

            // Update the state or variable holding the flashcards
            setSortedAndShuffledFlashcards(newFlashcards); // Assuming you're using React state
        },
        [sortedAndShuffledFlashcards, setSortedAndShuffledFlashcards]
    );

    const handleRestart = useCallback(() => {
        console.log("handleRestart triggered");
        const newCards = shuffleArray(
            sortedAndShuffledFlashcards.map((card) => ({
                ...card,
                count_correct_answers: 0,
                count_incorrect_answers: 0,
            }))
        );
        setSortedAndShuffledFlashcards(newCards);
        setFlashCards(newCards);
    }, [sortedAndShuffledFlashcards, setSortedAndShuffledFlashcards, setFlashCards]);

    //This function does a hard comparison of the two contents from cards
    const isContentEqual = (first, second) => {
        return JSON.stringify(first) === JSON.stringify(second);
    };

    /**
     * This function is used to update the flashcards state and the instructions state
     * @param {*} action - the action to be performed
     * @param {*} updated_card - the updated card object
     * @param {*} prev_id - the id of the previous card, only used for createCard action
     * @returns null
     */
    const updatedModifyCardHandler = useCallback(
        (action, updated_card = null, prev_id = null) => {
            console.log(
                "newModifyCardHandler triggered with action:",
                action,
                "for card:",
                updated_card ? updated_card.id : "null"
            );

            let newFlashcards = [];
            switch (action) {
                case "saveCard":
                    const existingCard = flashcards.find((card) => card.id === updated_card.id);
                    if (isContentEqual(existingCard, updated_card)) {
                        //console.log("Nothing to change");
                        return;
                    }
                    console.log("card changed");
                    setSavingCardIds((prevCards) => [...prevCards, updated_card.id]);
                    setFinishedSavingCardIds((prevCards) => [...prevCards, updated_card.id]);

                    newFlashcards = flashcards.map((each_card) => {
                        if (each_card.id === updated_card.id) {
                            return updated_card;
                        }
                        return each_card;
                    });
                    setFlashCards(newFlashcards);
                    updateInstructions("saveCard", { id: updated_card.id });
                    break;

                case "deleteCard":
                    newFlashcards = flashcards.filter((card) => card.id !== updated_card.id);
                    setFlashCards(newFlashcards);
                    updateInstructions("deleteCard", { id: updated_card.id });
                    if (newFlashcards.length === 0) {
                        updatedModifyCardHandler("createCard", {
                            id: uuidv4(),
                            question: "",
                            answer: initialValue,
                            type: "FlashcardQaPairHtml",
                            count_correct_answers: 0,
                            count_incorrect_answers: 0,
                        });
                    }
                    capture_event(EVENTS.FLASHCARDS_CARD_DELETED, {
                        id: updated_card.id,
                        type: updated_card.type,
                    });
                    break;

                case "createCard":
                    console.log("createCard triggered");
                    const index = flashcards.findIndex((card) => card.id === prev_id);
                    newFlashcards = [...flashcards];
                    newFlashcards.splice(index + 1, 0, { ...updated_card, id: uuidv4() });
                    setFlashCards(newFlashcards);
                    updateInstructions("createCard", { id: updated_card.id });
                    console.log("card created");
                    capture_event(EVENTS.FLASHCARDS_CARD_ADDED, {
                        id: updated_card.id,
                        type: updated_card.type,
                    });
                    break;

                case "increaseCorrect":
                    handleCardMarked(updated_card.id, 1);
                    setDidOneMove(true);

                    newFlashcards = flashcards.map((card) => {
                        if (card.id === updated_card.id) {
                            return {
                                ...card,
                                count_correct_answers: card.count_correct_answers + 1,
                            };
                        }
                        return card;
                    });
                    setFlashCards(newFlashcards);
                    updateInstructions("increaseCorrect", { id: updated_card.id });
                    capture_event(EVENTS.FLASHCARD_ANSWERED_CORRECTLY, {
                        id: updated_card.id,
                        type: updated_card.type,
                    });
                    break;

                case "increaseIncorrect":
                    handleCardMarked(updated_card.id, 0);
                    setDidOneMove(true);

                    newFlashcards = flashcards.map((card) => {
                        if (card.id === updated_card.id) {
                            return {
                                ...card,
                                count_incorrect_answers: card.count_incorrect_answers + 1,
                            };
                        }
                        return card;
                    });
                    setFlashCards(newFlashcards);
                    updateInstructions("increaseIncorrect", { id: updated_card.id });
                    capture_event(EVENTS.FLASHCARD_ANSWERED_INCORRECTLY, {
                        id: updated_card.id,
                        type: updated_card.type,
                    });
                    break;

                case "reset":
                    handleRestart();
                    updateInstructions("reset cards");
                    capture_event(EVENTS.FLASHCARDS_STODYMODE_RESETTED);
                    break;

                default:
                    break;
            }
        },
        [
            flashcards,
            setFlashCards,
            handleCardMarked,
            setDidOneMove,
            handleRestart,
            updateInstructions,
        ]
    );

    return (
        <>
            {!isStudyModeActive && (
                <div className={styles.container}>
                    <ModeTitleBar
                        title={flashcards_title}
                        onTitleChange={onFlashcardsTitleChange}
                        placeHolder={"Title of your deck"}
                    />
                    {flashcards.map((card, index) => (
                        <React.Fragment key={card.id}>
                            <Flashcard
                                getFlashcardsRefMap={getFlashcardsRefMap}
                                card={card}
                                isStudyModeActive={isStudyModeActive}
                                onCardChange={updatedModifyCardHandler}
                                savingCardIds={savingCardIds}
                                finishedSavingCardIds={finishedSavingCardIds}
                                cardNumber={index + 1}
                                numberOfCards={flashcards.length}
                            />
                            <AddCardContainer
                                id={card.id}
                                updatedModifyCardHandler={updatedModifyCardHandler}
                            />
                        </React.Fragment>
                    ))}
                    {flashcardStatus === "exceeded limit" && !isPaidUser && (
                        <InlineCardModal>
                            <p>Those were your first 5 sets of flashcards</p>
                            <p>Upgrade now to generate unlimited sets of flashcards</p>
                            <Button color={buttonStyles.btn_primary} onClick={toggleUpgradeModal}>
                                Upgrade now!
                            </Button>
                        </InlineCardModal>
                    )}
                    {flashcardStatus === "incomplete" && !isPaidUser && (
                        <InlineCardModal>
                            <p>
                                Those were the flashcards for the first 1000 words of your document
                            </p>
                            <p>Upgrade now to generate flashcards for the full document</p>
                            <Button color={buttonStyles.btn_primary} onClick={toggleUpgradeModal}>
                                Upgrade now!
                            </Button>
                        </InlineCardModal>
                    )}
                    {(flashcardStatus === "incomplete" || flashcardStatus === "exceeded limit") &&
                        isPaidUser && (
                            <InlineCardModal>
                                <h3>Congratulations for upgrading to a premium plan</h3>
                                <p>Choose the language of your full set of flashcards:</p>
                                <Button
                                    color={buttonStyles.btn_primary}
                                    onClick={() =>
                                        handleFlashcardCreation(
                                            document_id,
                                            "English",
                                            null,
                                            null,
                                            true
                                        )
                                    }>
                                    English
                                </Button>
                                <Button
                                    color={buttonStyles.btn_primary}
                                    onClick={() =>
                                        handleFlashcardCreation(
                                            document_id,
                                            "German",
                                            null,
                                            null,
                                            true
                                        )
                                    }>
                                    German
                                </Button>
                                <p></p>
                            </InlineCardModal>
                        )}
                </div>
            )}

            {isStudyModeActive && flashcards.length > 0 && (
                <div className={styles.containerStudyMode}>
                    <div className={styles.deckTitle}>
                        {(flashcards_title !== "" || flashcards_title !== null) && flashcards_title}
                    </div>

                    {isShowingAnalytics && (
                        <div className={styles.analytics}>
                            <div className={styles.smallAnalyticsTitle}>Info</div>
                            <div className={styles.smallAnalyticsText}>
                                Total Cards: {flashcards.length}
                            </div>
                            <div className={styles.smallAnalyticsText}>
                                Successfully learned: {getSuccessCount()} %
                            </div>
                            <div className={styles.smallAnalyticsTitle}>Analytics</div>
                            <>
                                {Object.entries(successCounts).map(([key, value]) => (
                                    <div className={styles.smallAnalyticsText} key={key}>
                                        {key}x successfully learned: {value}/{flashcards.length} (
                                        {flashcards.length === 0
                                            ? 0
                                            : Math.floor((value / flashcards.length) * 100)}
                                        %)
                                    </div>
                                ))}
                            </>
                            <div className={styles.smallAnalyticsTitle}>Logs</div>
                            <>
                                {flashcardLogs
                                    .slice(-5)
                                    .reverse()
                                    .map((log, index) => (
                                        <div key={index} className={styles.smallAnalyticsLog}>
                                            Last Time finished in mode Repeat {log.mode}x on:{" "}
                                            {log.date || "N/A"}
                                        </div>
                                    ))}
                            </>
                        </div>
                    )}

                    <div className={styles.cardContainer}>
                        {isFinished ? (
                            <FinishCard numberOfRepetitions={numberOfRepetitions} />
                        ) : (
                            <Flashcard
                                key={currentStudyCard.id}
                                card={currentStudyCard}
                                isStudyModeActive={isStudyModeActive}
                                onCardChange={updatedModifyCardHandler}
                                savingCardIds={savingCardIds}
                                finishedSavingCardIds={finishedSavingCardIds}
                                cardNumber={
                                    flashcards.findIndex(
                                        (card) => card.id === currentStudyCard.id
                                    ) + 1
                                }
                                numberOfCards={flashcards.length}
                                numberOfRepetitions={numberOfRepetitions}
                            />
                        )}
                        <div className={styles.menuContainer}>
                            {isFinished && (
                                <FlashCardButton
                                    type={"restart"}
                                    onClick={toggleResetModal}></FlashCardButton>
                            )}
                        </div>
                    </div>

                    <div className={styles.stats}>
                        <span className={styles.dataTitle}>Total cards: {flashcards.length}</span>
                        <span className={styles.dataTitle}></span>
                        <span className={styles.dataTitle}>
                            Successfully learned: {getSuccessCount()}%
                        </span>
                        <span className={styles.dataTitle}></span>
                        <span className={styles.analyticsTitle} onClick={toggleShowAnalytics}>
                            Click here for analytics
                        </span>
                    </div>
                </div>
            )}

            {isResetModalOpen && (
                <YesOrNoOverlayModal
                    isVisible={isResetModalOpen}
                    title={"Reset Progress"}
                    onClose={toggleResetModal}
                    onCancel={toggleResetModal}
                    onAccept={() => {
                        updatedModifyCardHandler("reset");
                        toggleResetModal();
                    }}>
                    Are you sure you want to reset your progress for this card deck? <br />
                    This action cannot be undone.
                </YesOrNoOverlayModal>
            )}
        </>
    );
};

export default React.memo(FlashcardContainer);
