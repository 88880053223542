import React, { useEffect } from "react";
import styles from "./infobanner.module.css"; // Import styles as a module

const InfoBanner = ({ message, visible, color, setBannerData }) => {
    useEffect(() => {
        // Set a timer to hide the banner after 3 seconds
        const timer = setTimeout(() => {
            setBannerData({ visible: false, message: "" });
        }, 5000);

        return () => clearTimeout(timer);
    });

    return (
        <div className={`${styles.infoBanner} ${visible ? styles.show : ""} ${styles[color]}`}>
            {message.split("\n").map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
            ))}
        </div>
    );
};

export default InfoBanner;
