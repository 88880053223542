import "font-awesome/css/font-awesome.min.css";
import cloneDeep from "lodash/cloneDeep";
import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import FlashCardButton from "../FlashcardsMode/FlashcardButton";
import Icon from "../Icon";
import styles from "./Flashcard.module.css";
import FlashcardEditor from "./FlashcardEditor";
import studystyles from "./FlashcardStudyMode.module.css";

const StudyCard = ({ numberOfRepetitions, card, cardPosition, onCardChange }) => {
    const cardTitle = card.question || "Title of your Card";
    const [showAnswer, setShowAnswer] = useState(false);

    const formatTextForDisplay = (text) => {
        return text.replace(/\n/g, "<br />");
    };

    const toggleFullView = () => {
        setShowAnswer(!showAnswer);
    };

    const StudyCardOnlyTitle = () => (
        <>
            <div className={studystyles.number}>{cardPosition}</div>
            <div
                className={studystyles.headlineLarge}
                dangerouslySetInnerHTML={{ __html: formatTextForDisplay(cardTitle) }}></div>
            <div className={studystyles.number}>
                {card.count_correct_answers}/{numberOfRepetitions}
            </div>
        </>
    );

    const StudyCardWithAnswer = () => (
        <>
            <div className={styles.cardHeadline}>
                <div
                    className={studystyles.headline}
                    dangerouslySetInnerHTML={{ __html: formatTextForDisplay(cardTitle) }}></div>
                <div className={styles.number}>{cardPosition}</div>
            </div>
            <div className={styles.cardContent}>
                <FlashcardEditor text={card.answer} isReadOnly={true}></FlashcardEditor>
                <div className={styles.controlIcons}>
                    {card.count_correct_answers}/{numberOfRepetitions}
                </div>
            </div>
        </>
    );

    return (
        <>
            <div className={studystyles.container}>
                <div className={studystyles.content}>
                    {showAnswer ? (
                        <StudyCardWithAnswer />
                    ) : (
                        // hideAnswer
                        <StudyCardOnlyTitle />
                    )}
                </div>
                <div className={studystyles.cardColoring}></div>
            </div>

            <div className={studystyles.menuContainer}>
                {!showAnswer && (
                    <FlashCardButton type={"uncover"} onClick={toggleFullView}></FlashCardButton>
                )}

                {showAnswer && (
                    <>
                        <FlashCardButton
                            type={"correct"}
                            onClick={() => onCardChange("increaseCorrect", card)}
                        />
                        <FlashCardButton
                            type={"incorrect"}
                            onClick={() => onCardChange("increaseIncorrect", card)}
                        />
                    </>
                )}
            </div>
        </>
    );
};

const EditCard = ({
    card,
    onQuestionChange,
    onAnswerChange,
    onSaveCard,
    onDeleteCard,
    cardPosition,
    savingCardIds,
    finishedSavingCardIds,
    getFlashcardsRefMap,
}) => {
    return (
        <div
            ref={(node) => {
                const map = getFlashcardsRefMap();
                if (node) {
                    map.set(card.id, node);
                } else {
                    map.delete(card.id);
                }
            }}
            className={styles.container}>
            <div className={styles.cardColoring}></div>
            <div className={styles.content}>
                <div className={styles.cardHeadline}>
                    <textarea
                        className={styles.headlineInput}
                        placeholder={"Title of your card"}
                        defaultValue={card.question}
                        onBlur={(e) => {
                            onQuestionChange(e.target.value);
                        }}></textarea>
                    <div className={styles.number}>{cardPosition}</div>
                </div>
                <div
                    className={styles.cardContent}
                    tabIndex="0" // Make the div focusable
                >
                    <FlashcardEditor
                        text={card.answer}
                        onTextChange={onAnswerChange}></FlashcardEditor>
                    <div className={styles.controlIcons}>
                        {savingCardIds.includes(card.id) ? (
                            <span className={styles.icon}>
                                <ClipLoader className={styles.cliploader} />
                            </span>
                        ) : finishedSavingCardIds.includes(card.id) ? (
                            <span className={styles.icon}>
                                <Icon name="CheckIcon" />
                            </span>
                        ) : (
                            <span className={styles.icon} onClick={onSaveCard}>
                                <Icon name="DiskIcon" />
                            </span>
                        )}
                        <span className={styles.icon} onClick={onDeleteCard}>
                            <Icon name="TrashIcon" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

const FlashcardQaPairHtml = ({
    card,
    isStudyModeActive,
    onCardChange,
    savingCardIds,
    finishedSavingCardIds,
    cardNumber,
    numberOfCards,
    numberOfRepetitions,
    getFlashcardsRefMap,
}) => {
    const cardPosition = `${cardNumber}/${numberOfCards}`;

    const handleQuestionChange = (question) => {
        const newCard = cloneDeep(card);

        const formattedQuestion = question
            .replace(/<div>/g, "\n")
            .replace(/<\/div>/g, "")
            .replace(/<br>/g, "\n")
            .replace(/&nbsp;/g, " ");
        newCard.question = formattedQuestion;
        onCardChange("saveCard", newCard);
    };

    const handleAnswerChange = (content) => {
        const newCard = cloneDeep(card);
        newCard.answer = content;
        onCardChange("saveCard", newCard);
    };

    const saveCardHandler = () => {
        console.log("saveCard triggered");
        onCardChange("saveCard", card);
    };

    const deleteCardHandler = () => {
        onCardChange("deleteCard", card);
    };

    return (
        <div className={styles.wrapper}>
            {!isStudyModeActive ? (
                <EditCard
                    card={card}
                    cardPosition={cardPosition}
                    onQuestionChange={handleQuestionChange}
                    onAnswerChange={handleAnswerChange}
                    onSaveCard={saveCardHandler}
                    onDeleteCard={deleteCardHandler}
                    savingCardIds={savingCardIds}
                    finishedSavingCardIds={finishedSavingCardIds}
                    getFlashcardsRefMap={getFlashcardsRefMap}
                />
            ) : (
                <StudyCard
                    onAnswerChange={handleAnswerChange}
                    card={card}
                    numberOfRepetitions={numberOfRepetitions}
                    onCardChange={onCardChange}
                    correctAmount={card.correct}
                    cardPosition={cardPosition}
                />
            )}
        </div>
    );
};

export default FlashcardQaPairHtml;
