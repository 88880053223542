import { throttle } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { v4 as uuidv4 } from "uuid";
import { capture_event, EVENTS } from "../../utils/analytics";
import Icon from "../Icon";
import DeleteButtonFileManager from "./DeleteButtonFileManager";
import styles from "./filemanagernew.module.css";
import PdfIcon from "./pdf_icon.png";
const EllipsisHorizontalIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="#718096">
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
        />
    </svg>
);

const EllipsisVerticalIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="#718096">
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
        />
    </svg>
);

const SortIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        className="bi bi-sort-down"
        viewBox="0 0 16 16">
        <path d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5M7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z" />
    </svg>
);

const SortIconSmall = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        fill="currentColor"
        className="bi bi-sort-down"
        viewBox="0 0 16 16">
        <path d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5M7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z" />
    </svg>
);

const AlphabetIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        className="bi bi-sort-alpha-down"
        viewBox="0 0 16 16">
        <path
            fillRule="evenodd"
            d="M10.082 5.629 9.664 7H8.598l1.789-5.332h1.234L13.402 7h-1.12l-.419-1.371zm1.57-.785L11 2.687h-.047l-.652 2.157z"
        />
        <path d="M12.96 14H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645zM4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293z" />
    </svg>
);

const AlphabetIconSmall = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        fill="currentColor"
        className="bi bi-sort-alpha-down"
        viewBox="0 0 16 16">
        <path
            fillRule="evenodd"
            d="M10.082 5.629 9.664 7H8.598l1.789-5.332h1.234L13.402 7h-1.12l-.419-1.371zm1.57-.785L11 2.687h-.047l-.652 2.157z"
        />
        <path d="M12.96 14H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645zM4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293z" />
    </svg>
);

function convertDateToString(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

function oppositeComparator(comparator) {
    return comparator === "date" ? "name" : "date";
}

const Workspace = ({
    title,
    open,
    fileSystem,
    setFileSytem,
    id,
    sorting,
    type,
    handleUploadButtonClick,
    handleFileChange,
    saveFilesystem,
    setIsUploading,
    setNewObjectId,
    children,
}) => {
    const [isOpen, setIsOpen] = useState(open);
    const [showControls, setShowControls] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(title);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const headerMessage = "Delete Folder";
    const message =
        'Are you sure you want to delete this folder?\n All files will be moved to "Deleted".\n This action is not revertable.';
    const [isVanishing, setIsVanishing] = useState(false);

    const deleteWithAnimation = () => {
        capture_event(EVENTS.FOLDER_DELETED, {
            folder_id: id,
        });
        setIsVanishing(true);
        setTimeout(() => {
            setFileSytem(deleteNodeAndMoveFiles(id, fileSystem));
            setIsVanishing(false);
        }, 500);
    };

    const handleNameChange = (e) => {
        setEditValue(e.target.value);
    };

    const handleBlur = () => {
        setIsEditing(false);
        if (editValue === "") {
            setEditValue("Unnamed Folder");
            setFileSytem(updateNameInFileSystem(id, "Unnamed Folder", fileSystem));
            return;
        }
        setFileSytem(updateNameInFileSystem(id, editValue, fileSystem));
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.target.blur(); // This will trigger handleBlur
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault(); // Necessary to allow dropping
        e.stopPropagation(); // Stops the event from bubbling up
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation(); // Stops the event from propagating to parent drop targets
        const fileId = e.dataTransfer.getData("application/reactflow");
        // Assuming you have a way to update the state of the file system
        setFileSytem(moveFiles(fileId, id, fileSystem, sorting));
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
        setFileSytem(updateOpenInFileSystem(id, !isOpen, fileSystem));
    };

    const toggleShowControls = () => {
        setShowControls(!showControls);
    };

    const toggleShowDeleteModal = () => {
        setShowDeleteModal(!showDeleteModal);
    };

    const triggerUpload = () => {
        handleUploadButtonClick(id);
    };

    const handleUpload = async (e) => {
        const document_id = await handleFileChange(e);
        if (document_id === null) {
            console.error("There was an error uploading the document.");
            setIsUploading(false);
            return;
        }
        const file = e.target.files[0];
        const date = new Date();
        const uploadedFile = {
            id: document_id,
            name: file.name,
            parentId: id,
            date: convertDateToString(date),
            open: false,
            type: "file",
            children: [],
        };
        setFileSytem(addNode(id, uploadedFile, fileSystem, sorting));
        await saveFilesystem();
        setIsUploading(false);
    };

    const nonFileChildren =
        id !== "workspace-1"
            ? React.Children.toArray(children)
                  .filter((child) => child.props.type !== "file")
                  .reverse()
            : React.Children.toArray(children).filter((child) => child.props.type !== "file");
    const fileChildren = React.Children.toArray(children)
        .filter((child) => child.props.type === "file")
        .reverse();

    const openWorkspace = (
        <div
            className={`${styles.workspaceopen} ${isVanishing ? styles.vanishing : ""}`}
            onDragOver={handleDragOver}
            onDrop={handleDrop}>
            {showDeleteModal && (
                <DeleteButtonFileManager
                    isVisible={showDeleteModal}
                    onReset={() => {
                        deleteWithAnimation();
                        toggleShowDeleteModal();
                    }}
                    onClose={toggleShowDeleteModal}
                    headerMessage={headerMessage}
                    message={message}
                />
            )}
            <div className={styles.workspaceheader}>
                <div className={styles.workspacetitle}>
                    <button onClick={toggleOpen} className={styles.arrowopen}>
                        <Icon name="ChevronDownIcon" />
                    </button>
                    {isEditing && id !== "workspace-1" ? (
                        <input
                            type="text"
                            value={editValue}
                            onChange={handleNameChange}
                            onBlur={handleBlur}
                            onKeyPress={handleKeyPress}
                            autoFocus
                            className={styles.inputformworkspace}
                        />
                    ) : (
                        <span className={styles.title} onDoubleClick={() => setIsEditing(true)}>
                            {title}
                        </span>
                    )}
                </div>
                <div className={styles.workspacecontrolbar}>
                    {showControls === false && id !== "workspace-1" && (
                        <button onClick={toggleShowControls} className={styles.ellipsis}>
                            <EllipsisVerticalIcon />
                        </button>
                    )}
                    {showControls === true && id !== "workspace-1" && (
                        <>
                            <button
                                className={styles.controlbaricon}
                                style={{ transform: "translateX(-1px)" }}
                                onClick={() => {
                                    setFileSytem(sortChildrenInFileSystem(fileSystem, id, sorting));
                                }}>
                                {sorting !== "date" ? <AlphabetIconSmall /> : <SortIconSmall />}
                            </button>
                            <button
                                className={styles.controlbaricon}
                                style={{ transform: "translateY(1px)" }}
                                onClick={triggerUpload}>
                                <Icon name="UploadIcon" width="17" height="17" />
                            </button>
                            <input
                                id={id}
                                type="file"
                                style={{ display: "none" }}
                                accept=".pdf,.docx,.DOCX,.doc,.DOC,.pptx,.PPTX,.ppt,.PPT,.odt,.ODT"
                                onChange={handleUpload}
                            />
                            <button
                                className={styles.controlbaricon}
                                style={{ transform: "translateY(-1px)" }}
                                onClick={toggleShowDeleteModal}>
                                <Icon name="TrashIcon" width="17" height="17" />
                            </button>
                            <button
                                className={styles.controlbaricon}
                                style={{ transform: "translateY(-1px)" }}
                                onClick={() => {
                                    setIsEditing(true);
                                }}>
                                <Icon name="PencilIcon" width="17" height="17" />
                            </button>
                            <button
                                className={styles.controlbaricon}
                                onClick={() => {
                                    setFileSytem(
                                        addSubworkspaceToWorkspace(
                                            id,
                                            fileSystem,
                                            setNewObjectId,
                                            sorting
                                        )
                                    );
                                }}>
                                <Icon name="AddFolderIcon" width="17" height="17" />
                            </button>
                            <button onClick={toggleShowControls} className={styles.ellipsis}>
                                <EllipsisHorizontalIcon />
                            </button>
                        </>
                    )}
                </div>
            </div>
            <div className={styles.filestoreworkspace}>
                <div className={styles.filestoreworkspacefiles}>{fileChildren}</div>
                {nonFileChildren}
            </div>
        </div>
    );

    const closedWorkspace = (
        <div
            id={id}
            className={`${styles.workspaceclosed} ${isVanishing ? styles.vanishing : ""}`}
            onDragOver={handleDragOver}
            onDrop={handleDrop}>
            {showDeleteModal && (
                <DeleteButtonFileManager
                    isVisible={showDeleteModal}
                    onReset={() => {
                        deleteWithAnimation();
                        toggleShowDeleteModal();
                    }}
                    onClose={toggleShowDeleteModal}
                    headerMessage={headerMessage}
                    message={message}
                />
            )}
            <div className={styles.workspaceheader}>
                <div className={styles.workspacetitle}>
                    <button onClick={toggleOpen} className={styles.arrowclosed}>
                        <Icon name="ChevronRightIcon" />
                    </button>
                    {isEditing && id !== "workspace-1" ? (
                        <input
                            type="text"
                            value={editValue}
                            onChange={handleNameChange}
                            onBlur={handleBlur}
                            onKeyPress={handleKeyPress}
                            autoFocus
                            className={styles.inputformworkspace}
                        />
                    ) : (
                        <span
                            id={id + 1}
                            className={styles.title}
                            onDoubleClick={() => setIsEditing(true)}>
                            {title}
                        </span>
                    )}
                </div>
                <div className={styles.workspacecontrolbar}>
                    {showControls === false && id !== "workspace-1" && (
                        <button onClick={toggleShowControls} className={styles.ellipsis}>
                            <EllipsisVerticalIcon />
                        </button>
                    )}
                    {showControls === true && id !== "workspace-1" && (
                        <>
                            <button
                                className={styles.controlbaricon}
                                style={{ transform: "translateX(-1px)" }}
                                onClick={() => {
                                    setFileSytem(sortChildrenInFileSystem(fileSystem, id, sorting));
                                }}>
                                {sorting !== "date" ? <AlphabetIconSmall /> : <SortIconSmall />}
                            </button>
                            <button
                                className={styles.controlbaricon}
                                style={{ transform: "translateY(1px)" }}
                                onClick={triggerUpload}>
                                <Icon name="UploadIcon" width="17" height="17" />
                            </button>
                            <input
                                id={id}
                                type="file"
                                style={{ display: "none" }}
                                accept=".pdf,.docx,.DOCX,.doc,.DOC,.pptx,.PPTX,.ppt,.PPT,.odt,.ODT"
                                onChange={handleUpload}
                            />
                            <button
                                className={styles.controlbaricon}
                                style={{ transform: "translateY(-1px)" }}
                                onClick={toggleShowDeleteModal}>
                                <Icon name="TrashIcon" width="17" height="17" />
                            </button>
                            <button
                                className={styles.controlbaricon}
                                style={{ transform: "translateY(-1px)" }}
                                onClick={() => {
                                    setIsEditing(true);
                                }}>
                                <Icon name="PencilIcon" width="17" height="17" />
                            </button>
                            <button
                                className={styles.controlbaricon}
                                onClick={() => {
                                    setFileSytem(
                                        addSubworkspaceToWorkspace(
                                            id,
                                            fileSystem,
                                            setNewObjectId,
                                            sorting
                                        )
                                    );
                                }}>
                                <Icon name="AddFolderIcon" width="17" height="17" />
                            </button>
                            <button onClick={toggleShowControls} className={styles.ellipsis}>
                                <EllipsisHorizontalIcon />
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );

    return isOpen ? openWorkspace : closedWorkspace;
};

const SubWorkSpace = ({
    title,
    open,
    fileSystem,
    setFileSytem,
    id,
    sorting,
    type,
    handleUploadButtonClick,
    handleFileChange,
    saveFilesystem,
    setIsUploading,
    setNewObjectId,
    children,
}) => {
    const [isOpen, setIsOpen] = useState(open);
    const [showControls, setShowControls] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(title);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showClearDeletedModal, setShowClearDeletedModal] = useState(false);
    const headerMessage = "Delete Folder";
    const message =
        'Are you sure you want to delete this folder?\n All files will be moved to "Deleted".\n This action is not revertable.';
    const headerMessage2 = "Clear Deleted";
    const message2 =
        "Are you sure you want to delete this files permanently?\n This action is not revertable.";

    const [isVanishing, setIsVanishing] = useState(false);

    const deleteWithAnimation = () => {
        setIsVanishing(true);
        setTimeout(() => {
            setFileSytem(deleteNodeAndMoveFiles(id, fileSystem));
            setIsVanishing(false);
        }, 500);
    };

    const handleNameChange = (e) => {
        setEditValue(e.target.value);
    };

    const handleBlur = () => {
        setIsEditing(false);
        if (editValue === "") {
            setEditValue("Unnamed Folder");
            setFileSytem(updateNameInFileSystem(id, "Unnamed Folder", fileSystem));
            return;
        }
        setFileSytem(updateNameInFileSystem(id, editValue, fileSystem));
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.target.blur(); // This will trigger handleBlur
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault(); // Necessary to allow dropping
        e.stopPropagation(); // Stops the event from bubbling up
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation(); // Stops the event from propagating to parent drop targets
        const fileId = e.dataTransfer.getData("application/reactflow");
        // Assuming you have a way to update the state of the file system
        setFileSytem(moveFiles(fileId, id, fileSystem, sorting));
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
        setFileSytem(updateOpenInFileSystem(id, !isOpen, fileSystem));
    };

    const toggleShowControls = () => {
        setShowControls(!showControls);
    };

    const toggleShowDeleteModal = () => {
        setShowDeleteModal(!showDeleteModal);
    };

    const toggleShowClearDeletedModal = () => {
        setShowClearDeletedModal(!showClearDeletedModal);
    };

    const triggerUpload = () => {
        handleUploadButtonClick(id);
    };

    const handleUpload = async (e) => {
        const document_id = await handleFileChange(e);
        if (document_id === null) {
            console.error("There was an error uploading the document.");
            setIsUploading(false);
            return;
        }
        const file = e.target.files[0];
        const date = new Date();
        const uploadedFile = {
            id: document_id,
            name: file.name,
            parentId: id,
            date: convertDateToString(date),
            open: false,
            type: "file",
            children: [],
        };
        setFileSytem(addNode(id, uploadedFile, fileSystem, sorting));
        await saveFilesystem();
        setIsUploading(false);
    };

    const nonFileChildren = React.Children.toArray(children)
        .filter((child) => child.props.type !== "file")
        .reverse();
    const fileChildren = React.Children.toArray(children)
        .filter((child) => child.props.type === "file")
        .reverse();

    const openSubworkspace = (
        <div
            className={`${styles.subworkspaceopen} ${isVanishing ? styles.vanishing : ""}`}
            onDragOver={handleDragOver}
            onDrop={handleDrop}>
            {showDeleteModal && (
                <DeleteButtonFileManager
                    isVisible={showDeleteModal}
                    onReset={() => {
                        deleteWithAnimation();
                        toggleShowDeleteModal();
                    }}
                    onClose={toggleShowDeleteModal}
                    headerMessage={headerMessage}
                    message={message}
                />
            )}
            {showClearDeletedModal && (
                <DeleteButtonFileManager
                    isVisible={showClearDeletedModal}
                    onReset={() => {
                        setFileSytem(deleteFilesPermanent(fileSystem));
                        toggleShowClearDeletedModal();
                    }}
                    onClose={toggleShowClearDeletedModal}
                    headerMessage={headerMessage2}
                    message={message2}
                />
            )}
            <div className={styles.subworkspaceheader}>
                <div className={styles.subworkspacetitle}>
                    <button onClick={toggleOpen} className={styles.subspacearrowopen}>
                        <Icon name="ChevronDownIcon" />
                    </button>
                    {isEditing && id !== "subworkspace-1-1" && id !== "subworkspace-1-2" ? (
                        <input
                            type="text"
                            value={editValue}
                            onChange={handleNameChange}
                            onBlur={handleBlur}
                            onKeyPress={handleKeyPress}
                            autoFocus
                            className={styles.inputformsubworkspace}
                        />
                    ) : (
                        <span
                            className={styles.subspacetitle}
                            onDoubleClick={() => setIsEditing(true)}>
                            {title}
                        </span>
                    )}
                </div>
                <div className={styles.subworkspacecontrolbar}>
                    {showControls === false &&
                        id !== "subworkspace-1-1" &&
                        id !== "subworkspace-1-2" && (
                            <button onClick={toggleShowControls} className={styles.ellipsis}>
                                <EllipsisVerticalIcon />
                            </button>
                        )}
                    {showControls === true &&
                        id !== "subworkspace-1-1" &&
                        id !== "subworkspace-1-2" && (
                            <>
                                <button
                                    className={styles.controlbaricon}
                                    style={{ transform: "translateX(-1px)" }}
                                    onClick={() => {
                                        setFileSytem(
                                            sortChildrenInFileSystem(fileSystem, id, sorting)
                                        );
                                    }}>
                                    {sorting !== "date" ? <AlphabetIconSmall /> : <SortIconSmall />}
                                </button>
                                <button
                                    className={styles.controlbaricon}
                                    style={{ transform: "translateY(1px)" }}
                                    onClick={triggerUpload}>
                                    <Icon name="UploadIcon" width="17" height="17" />
                                </button>
                                <input
                                    id={id}
                                    type="file"
                                    style={{ display: "none" }}
                                    accept=".pdf,.docx,.DOCX,.doc,.DOC,.pptx,.PPTX,.ppt,.PPT,.odt,.ODT"
                                    onChange={handleUpload}
                                />
                                <button
                                    className={styles.controlbaricon}
                                    style={{ transform: "translateY(-1px)" }}
                                    onClick={toggleShowDeleteModal}>
                                    <Icon name="TrashIcon" width="17" height="17" />
                                </button>
                                <button
                                    className={styles.controlbaricon}
                                    style={{ transform: "translateY(-1px)" }}
                                    onClick={() => {
                                        setIsEditing(true);
                                    }}>
                                    <Icon name="PencilIcon" width="17" height="17" />
                                </button>
                                <button
                                    className={styles.controlbaricon}
                                    onClick={() => {
                                        setFileSytem(
                                            addFolderToSubworkspace(
                                                id,
                                                fileSystem,
                                                setNewObjectId,
                                                sorting
                                            )
                                        );
                                    }}>
                                    <Icon name="AddFolderIcon" width="17" height="17" />
                                </button>
                                <button onClick={toggleShowControls} className={styles.ellipsis}>
                                    <EllipsisHorizontalIcon />
                                </button>
                            </>
                        )}
                    {id === "subworkspace-1-1" && (
                        <>
                            <button
                                className={styles.controlbaricon}
                                style={{ transform: "translateY(-1px) translateX(-8px)" }}
                                onClick={triggerUpload}>
                                <Icon name="UploadIcon" width="17" height="17" />
                            </button>
                            <input
                                id={id}
                                type="file"
                                style={{ display: "none" }}
                                accept=".pdf,.docx,.DOCX,.doc,.DOC,.pptx,.PPTX,.ppt,.PPT,.odt,.ODT"
                                onChange={handleUpload}
                            />
                        </>
                    )}
                    {id === "subworkspace-1-2" && (
                        <>
                            <button
                                className={styles.controlbaricon}
                                style={{ transform: "translateY(-1px) translateX(-8px)" }}
                                onClick={toggleShowClearDeletedModal}>
                                <Icon name="TrashIcon" width="17" height="17" />
                            </button>
                        </>
                    )}
                </div>
            </div>
            <div className={styles.filestoresubspace}>
                <div className={styles.filestoresubspacefiles}>{fileChildren}</div>
                {nonFileChildren}
            </div>
        </div>
    );

    const closedSubworkspace = (
        <div
            id={id}
            className={`${styles.subworkspaceclosed} ${isVanishing ? styles.vanishing : ""}`}
            onDragOver={handleDragOver}
            onDrop={handleDrop}>
            {showDeleteModal && (
                <DeleteButtonFileManager
                    isVisible={showDeleteModal}
                    onReset={() => {
                        deleteWithAnimation();
                        toggleShowDeleteModal();
                    }}
                    onClose={toggleShowDeleteModal}
                    headerMessage={headerMessage}
                    message={message}
                />
            )}
            {showClearDeletedModal && (
                <DeleteButtonFileManager
                    isVisible={showClearDeletedModal}
                    onReset={() => {
                        setFileSytem(deleteFilesPermanent(fileSystem));
                        toggleShowClearDeletedModal();
                    }}
                    onClose={toggleShowClearDeletedModal}
                    headerMessage={headerMessage2}
                    message={message2}
                />
            )}
            <div className={styles.subworkspaceheader}>
                <div className={styles.subworkspacetitle}>
                    <button onClick={toggleOpen} className={styles.subspacearrowclosed}>
                        <Icon name="ChevronRightIcon" />
                    </button>
                    {isEditing && id !== "subworkspace-1-1" && id !== "subworkspace-1-2" ? (
                        <input
                            type="text"
                            value={editValue}
                            onChange={handleNameChange}
                            onBlur={handleBlur}
                            onKeyPress={handleKeyPress}
                            autoFocus
                            className={styles.inputformsubworkspace}
                        />
                    ) : (
                        <span
                            id={id + 1}
                            className={styles.subspacetitle}
                            onDoubleClick={() => setIsEditing(true)}>
                            {title}
                        </span>
                    )}
                </div>
                <div className={styles.subworkspacecontrolbar}>
                    {showControls === false &&
                        id !== "subworkspace-1-1" &&
                        id !== "subworkspace-1-2" && (
                            <button onClick={toggleShowControls} className={styles.ellipsis}>
                                <EllipsisVerticalIcon />
                            </button>
                        )}
                    {showControls === true &&
                        id !== "subworkspace-1-1" &&
                        id !== "subworkspace-1-2" && (
                            <>
                                <button
                                    className={styles.controlbaricon}
                                    style={{ transform: "translateX(-1px)" }}
                                    onClick={() => {
                                        setFileSytem(
                                            sortChildrenInFileSystem(fileSystem, id, sorting)
                                        );
                                    }}>
                                    {sorting !== "date" ? <AlphabetIconSmall /> : <SortIconSmall />}
                                </button>
                                <button
                                    className={styles.controlbaricon}
                                    style={{ transform: "translateY(1px)" }}
                                    onClick={triggerUpload}>
                                    <Icon name="UploadIcon" width="17" height="17" />
                                </button>
                                <input
                                    id={id}
                                    type="file"
                                    style={{ display: "none" }}
                                    accept=".pdf,.docx,.DOCX,.doc,.DOC,.pptx,.PPTX,.ppt,.PPT,.odt,.ODT"
                                    onChange={handleUpload}
                                />
                                <button
                                    className={styles.controlbaricon}
                                    style={{ transform: "translateY(-1px)" }}
                                    onClick={toggleShowDeleteModal}>
                                    <Icon name="TrashIcon" width="17" height="17" />
                                </button>
                                <button
                                    className={styles.controlbaricon}
                                    style={{ transform: "translateY(-1px)" }}
                                    onClick={() => {
                                        setIsEditing(true);
                                    }}>
                                    <Icon name="PencilIcon" width="17" height="17" />
                                </button>
                                <button
                                    className={styles.controlbaricon}
                                    onClick={() => {
                                        setFileSytem(
                                            addFolderToSubworkspace(
                                                id,
                                                fileSystem,
                                                setNewObjectId,
                                                sorting
                                            )
                                        );
                                    }}>
                                    <Icon name="AddFolderIcon" width="17" height="17" />
                                </button>
                                <button onClick={toggleShowControls} className={styles.ellipsis}>
                                    <EllipsisHorizontalIcon />
                                </button>
                            </>
                        )}
                    {id === "subworkspace-1-1" && (
                        <>
                            <button
                                className={styles.controlbaricon}
                                style={{ transform: "translateY(-1px) translateX(-8px)" }}
                                onClick={triggerUpload}>
                                <Icon name="UploadIcon" width="17" height="17" />
                            </button>
                            <input
                                id="home"
                                type="file"
                                style={{ display: "none" }}
                                accept=".pdf,.docx,.DOCX,.doc,.DOC,.pptx,.PPTX,.ppt,.PPT,.odt,.ODT"
                                onChange={handleUpload}
                            />
                        </>
                    )}
                    {id === "subworkspace-1-2" && (
                        <>
                            <button
                                className={styles.controlbaricon}
                                style={{ transform: "translateY(-1px) translateX(-8px)" }}
                                onClick={toggleShowClearDeletedModal}>
                                <Icon name="TrashIcon" width="17" height="17" />
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );

    return isOpen ? openSubworkspace : closedSubworkspace;
};

const Folder = ({
    title,
    open,
    fileSystem,
    setFileSytem,
    id,
    sorting,
    type,
    handleUploadButtonClick,
    handleFileChange,
    saveFilesystem,
    setIsUploading,
    children,
}) => {
    const [isOpen, setIsOpen] = useState(open);
    const [showControls, setShowControls] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(title);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const headerMessage = "Delete Folder";
    const message =
        'Are you sure you want to delete this folder?\n All files will be moved to "Deleted".\n This action is not revertable.';
    const [isVanishing, setIsVanishing] = useState(false);

    const deleteWithAnimation = () => {
        setIsVanishing(true);
        setTimeout(() => {
            setFileSytem(deleteNodeAndMoveFiles(id, fileSystem));
            setIsVanishing(false);
        }, 500);
    };

    const handleNameChange = (e) => {
        setEditValue(e.target.value);
    };

    const handleBlur = () => {
        setIsEditing(false);
        if (editValue === "") {
            setEditValue("Unnamed Folder");
            setFileSytem(updateNameInFileSystem(id, "Unnamed Folder", fileSystem));
            return;
        }
        setFileSytem(updateNameInFileSystem(id, editValue, fileSystem));
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.target.blur(); // This will trigger handleBlur
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault(); // Necessary to allow dropping
        e.stopPropagation(); // Stops the event from bubbling up
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const fileId = e.dataTransfer.getData("application/reactflow");
        e.stopPropagation(); // Stops the event from propagating to parent drop targets
        // Assuming you have a way to update the state of the file system
        setFileSytem(moveFiles(fileId, id, fileSystem, sorting));
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
        setFileSytem(updateOpenInFileSystem(id, !isOpen, fileSystem));
    };

    const toggleShowControls = () => {
        setShowControls(!showControls);
    };

    const toggleShowDeleteModal = () => {
        setShowDeleteModal(!showDeleteModal);
    };

    const triggerUpload = () => {
        handleUploadButtonClick(id);
    };

    const handleUpload = async (e) => {
        const document_id = await handleFileChange(e);
        if (document_id === null) {
            console.error("There was an error uploading the document.");
            setIsUploading(false);
            return;
        }
        const file = e.target.files[0];
        const date = new Date();
        const uploadedFile = {
            id: document_id,
            name: file.name,
            parentId: id,
            date: convertDateToString(date),
            open: false,
            type: "file",
            children: [],
        };
        setFileSytem(addNode(id, uploadedFile, fileSystem, sorting));
        await saveFilesystem();
        setIsUploading(false);
    };

    const fileChildren = React.Children.toArray(children)
        .filter((child) => child.props.type === "file")
        .reverse();

    const openFolder = (
        <div
            className={`${styles.folderopen} ${isVanishing ? styles.vanishing : ""}`}
            onDragOver={handleDragOver}
            onDrop={handleDrop}>
            {showDeleteModal && (
                <DeleteButtonFileManager
                    isVisible={showDeleteModal}
                    onReset={() => {
                        deleteWithAnimation();
                        toggleShowDeleteModal();
                    }}
                    onClose={toggleShowDeleteModal}
                    headerMessage={headerMessage}
                    message={message}
                />
            )}
            <div className={styles.folderheader}>
                <div className={styles.foldertitlewrapper}>
                    <button onClick={toggleOpen} className={styles.folderarrowopen}>
                        <Icon name="ChevronDownIcon" />
                    </button>
                    {isEditing ? (
                        <input
                            type="text"
                            value={editValue}
                            onChange={handleNameChange}
                            onBlur={handleBlur}
                            onKeyPress={handleKeyPress}
                            autoFocus
                            className={styles.inputformfolder}
                        />
                    ) : (
                        <span
                            className={styles.foldertitle}
                            onDoubleClick={() => setIsEditing(true)}>
                            {title}
                        </span>
                    )}
                </div>
                <div className={styles.foldercontrolbar}>
                    {showControls === false && (
                        <button onClick={toggleShowControls} className={styles.ellipsis}>
                            <EllipsisVerticalIcon />
                        </button>
                    )}
                    {showControls === true && (
                        <>
                            <button
                                className={styles.controlbaricon}
                                style={{ transform: "translateX(-1px)" }}
                                onClick={() => {
                                    setFileSytem(sortChildrenInFileSystem(fileSystem, id, sorting));
                                }}>
                                {sorting !== "date" ? <AlphabetIconSmall /> : <SortIconSmall />}
                            </button>
                            <button
                                className={styles.controlbaricon}
                                style={{ transform: "translateY(1px)" }}
                                onClick={triggerUpload}>
                                <Icon name="UploadIcon" width="17" height="17" />
                            </button>
                            <input
                                id={id}
                                type="file"
                                style={{ display: "none" }}
                                accept=".pdf,.docx,.DOCX,.doc,.DOC,.pptx,.PPTX,.ppt,.PPT,.odt,.ODT"
                                onChange={handleUpload}
                            />
                            <button
                                className={styles.controlbaricon}
                                style={{ transform: "translateY(-1px)" }}
                                onClick={() => {
                                    setIsEditing(true);
                                }}>
                                <Icon name="PencilIcon" width="17" height="17" />
                            </button>
                            <button
                                className={styles.controlbaricon}
                                style={{ transform: "translateY(-1px)" }}
                                onClick={toggleShowDeleteModal}>
                                <Icon name="TrashIcon" width="17" height="17" />
                            </button>
                            <button onClick={toggleShowControls} className={styles.ellipsis}>
                                <EllipsisHorizontalIcon />
                            </button>
                        </>
                    )}
                </div>
            </div>
            <div className={styles.filestorefolder}>{fileChildren}</div>
        </div>
    );

    const closedFolder = (
        <div
            id={id}
            className={`${styles.folderclosed} ${isVanishing ? styles.vanishing : ""}`}
            onDragOver={handleDragOver}
            onDrop={handleDrop}>
            {showDeleteModal && (
                <DeleteButtonFileManager
                    isVisible={showDeleteModal}
                    onReset={() => {
                        deleteWithAnimation();
                        toggleShowDeleteModal();
                    }}
                    onClose={toggleShowDeleteModal}
                    headerMessage={headerMessage}
                    message={message}
                />
            )}
            <div className={styles.folderheader}>
                <div className={styles.foldertitlewrapper}>
                    <button onClick={toggleOpen} className={styles.folderarrowopen}>
                        <Icon name="ChevronRightIcon" />
                    </button>
                    {isEditing ? (
                        <input
                            type="text"
                            value={editValue}
                            onChange={handleNameChange}
                            onBlur={handleBlur}
                            onKeyPress={handleKeyPress}
                            autoFocus
                            className={styles.inputformfolder}
                        />
                    ) : (
                        <span
                            id={id + 1}
                            className={styles.foldertitle}
                            onDoubleClick={() => setIsEditing(true)}>
                            {title}
                        </span>
                    )}
                </div>
                <div className={styles.foldercontrolbar}>
                    {showControls === false && (
                        <button onClick={toggleShowControls} className={styles.ellipsis}>
                            <EllipsisVerticalIcon />
                        </button>
                    )}
                    {showControls === true && (
                        <>
                            <button
                                className={styles.controlbaricon}
                                style={{ transform: "translateX(-1px)" }}
                                onClick={() => {
                                    setFileSytem(sortChildrenInFileSystem(fileSystem, id, sorting));
                                }}>
                                {sorting !== "date" ? <AlphabetIconSmall /> : <SortIconSmall />}
                            </button>
                            <button
                                className={styles.controlbaricon}
                                style={{ transform: "translateY(1px)" }}
                                onClick={triggerUpload}>
                                <Icon name="UploadIcon" width="17" height="17" />
                            </button>
                            <input
                                id={id}
                                type="file"
                                style={{ display: "none" }}
                                accept=".pdf,.docx,.DOCX,.doc,.DOC,.pptx,.PPTX,.ppt,.PPT,.odt,.ODT"
                                onChange={handleUpload}
                            />
                            <button
                                className={styles.controlbaricon}
                                style={{ transform: "translateY(-1px)" }}
                                onClick={() => {
                                    setIsEditing(true);
                                }}>
                                <Icon name="PencilIcon" width="17" height="17" />
                            </button>
                            <button
                                className={styles.controlbaricon}
                                style={{ transform: "translateY(-1px)" }}
                                onClick={toggleShowDeleteModal}>
                                <Icon name="TrashIcon" width="17" height="17" />
                            </button>
                            <button onClick={toggleShowControls} className={styles.ellipsis}>
                                <EllipsisHorizontalIcon />
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );

    return isOpen ? openFolder : closedFolder;
};

const File = ({ title, fileSystem, setFileSytem, id, type, onFileSelect }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(title);
    const [isVanishing, setIsVanishing] = useState(false);

    const deleteWithAnimation = () => {
        capture_event(EVENTS.DOCUMENT_DELETED, {
            document_id: id,
        });
        setIsVanishing(true);
        setTimeout(() => {
            setFileSytem(deleteNodeAndMoveFiles(id, fileSystem));
            setIsVanishing(false);
        }, 500);
    };

    const handleNameChange = (e) => {
        setEditValue(e.target.value);
    };

    const handleBlur = () => {
        setIsEditing(false);
        if (editValue === "") {
            setEditValue("Unnamed File");
            setFileSytem(updateNameInFileSystem(id, "Unnamed File", fileSystem));
            return;
        }
        setFileSytem(updateNameInFileSystem(id, editValue, fileSystem));
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.target.blur(); // This will trigger handleBlur
        }
    };

    const handleDragStart = (e) => {
        e.dataTransfer.setData("application/reactflow", id); // Use a MIME type
        e.dataTransfer.effectAllowed = "move";
    };

    // HANDOVER: this renders a single File!!!
    // TODO: change from Icon-View to List-View
    const Fileview = (
        <div
            className={`${styles.filewrapper} ${isVanishing ? styles.vanishing : ""}`}
            draggable
            onDragStart={handleDragStart}>
            <div
                className={styles.fileicon}
                onClick={() => {
                    onFileSelect(id, title);
                }}>
                <img src={PdfIcon} alt={title} className={styles.pdficon} />
            </div>
            <div className={styles.filetitlewrapper}>
                {isEditing ? (
                    <textarea
                        type="text"
                        value={editValue}
                        onChange={handleNameChange}
                        onBlur={handleBlur}
                        onDoubleClick={handleKeyPress}
                        rows={4}
                        autoFocus
                        className={styles.inputformfile}
                    />
                ) : (
                    <span className={styles.filetitle} onDoubleClick={() => setIsEditing(true)}>
                        {title.length >= 49
                            ? title.substring(0, 22) + "..." + title.slice(-22)
                            : title}
                    </span>
                )}
            </div>
            {!isEditing && (
                <div className={styles.fileEditIcons}>
                    <span
                        className={styles.filerenameicon}
                        onClick={() => {
                            setIsEditing(true);
                        }}>
                        <Icon name="PencilIcon" width="17" height="17" />
                    </span>
                    <span
                        className={`${styles.filedeleteicon} ${styles.deleteIcon}`}
                        onClick={deleteWithAnimation}>
                        <Icon name="TrashIcon" width="17" height="17" />
                    </span>
                </div>
            )}
        </div>
    );

    return Fileview;
};

const FileViewOnly = ({ id, title, onFileSelect }) => {
    return (
        <div className={styles.fileviewonlywrapper}>
            <div
                className={styles.fileicon}
                onClick={() => {
                    onFileSelect(id, title);
                }}>
                <Icon name="FileIcon" />
            </div>
            <div className={styles.filetitlewrapper}>
                <span className={styles.filetitleviewonly}>{title}</span>
            </div>
        </div>
    );
};

const renderNodes = (
    nodes,
    fileSystem,
    setFileSytem,
    handleUploadButtonClick,
    handleFileChange,
    onFileSelect,
    trigger_save_filesystem,
    setIsUploading,
    saveFilesystem,
    setNewObjectId
) => {
    if (!Array.isArray(nodes)) {
        console.log(nodes);
        return null;
    }

    return nodes.map((node) => {
        switch (node.type) {
            case "workspace":
                return (
                    <Workspace
                        key={node.id}
                        id={node.id}
                        title={node.name}
                        open={node.open}
                        fileSystem={fileSystem}
                        setFileSytem={setFileSytem}
                        type={node.type}
                        handleUploadButtonClick={handleUploadButtonClick}
                        handleFileChange={handleFileChange}
                        trigger_save_filesystem={trigger_save_filesystem}
                        setIsUploading={setIsUploading}
                        saveFilesystem={saveFilesystem}
                        setNewObjectId={setNewObjectId}
                        sorting={node.sorting}>
                        {renderNodes(
                            node.children,
                            fileSystem,
                            setFileSytem,
                            handleUploadButtonClick,
                            handleFileChange,
                            onFileSelect,
                            trigger_save_filesystem,
                            saveFilesystem,
                            setIsUploading,
                            setNewObjectId
                        )}
                    </Workspace>
                );
            case "subworkspace":
                return (
                    <SubWorkSpace
                        key={node.id}
                        id={node.id}
                        title={node.name}
                        open={node.open}
                        fileSystem={fileSystem}
                        setFileSytem={setFileSytem}
                        type={node.type}
                        handleUploadButtonClick={handleUploadButtonClick}
                        handleFileChange={handleFileChange}
                        trigger_save_filesystem={trigger_save_filesystem}
                        setIsUploading={setIsUploading}
                        saveFilesystem={saveFilesystem}
                        setNewObjectId={setNewObjectId}
                        sorting={node.sorting}>
                        {renderNodes(
                            node.children,
                            fileSystem,
                            setFileSytem,
                            handleUploadButtonClick,
                            handleFileChange,
                            onFileSelect,
                            trigger_save_filesystem,
                            saveFilesystem,
                            setIsUploading,
                            setNewObjectId
                        )}
                    </SubWorkSpace>
                );
            case "folder":
                return (
                    <Folder
                        key={node.id}
                        id={node.id}
                        title={node.name}
                        open={node.open}
                        fileSystem={fileSystem}
                        setFileSytem={setFileSytem}
                        type={node.type}
                        handleUploadButtonClick={handleUploadButtonClick}
                        handleFileChange={handleFileChange}
                        trigger_save_filesystem={trigger_save_filesystem}
                        setIsUploading={setIsUploading}
                        saveFilesystem={saveFilesystem}
                        setNewObjectId={setNewObjectId}
                        sorting={node.sorting}>
                        {renderNodes(
                            node.children,
                            fileSystem,
                            setFileSytem,
                            handleUploadButtonClick,
                            handleFileChange,
                            onFileSelect,
                            trigger_save_filesystem,
                            saveFilesystem,
                            setIsUploading,
                            setNewObjectId
                        )}
                    </Folder>
                );
            case "file":
                return (
                    <File
                        key={node.id}
                        id={node.id}
                        title={node.name}
                        fileSystem={fileSystem}
                        setFileSytem={setFileSytem}
                        type={node.type}
                        onFileSelect={onFileSelect}
                    />
                );
            default:
                return null;
        }
    });
};

function addNode(parentId, newNode, fileSystem, sorting) {
    // This is a recursive function to search for the parent node and add the new node to its children
    function addNodeRecursive(nodes) {
        return nodes.map((node) => {
            if (node.id === parentId) {
                if (node.children) {
                    return { ...node, children: [...node.children, newNode] };
                }
            } else if (node.children) {
                return { ...node, children: addNodeRecursive(node.children) };
            }
            return node;
        });
    }

    const newFileSystem = addNodeRecursive(fileSystem);
    const sortedNewFileSystem = sortChildrenInFileSystem(
        newFileSystem,
        parentId,
        oppositeComparator(sorting)
    );
    return sortedNewFileSystem;
}

function deleteFilesPermanent(fileSystem) {
    capture_event(EVENTS.FILEMANAGER_TRASH_CLEARED);
    function clearDeletedFolder(nodes) {
        return nodes.map((node) => {
            if (node.id === "subworkspace-1-2") {
                node.children = [];
            } else if (node.children) {
                // Recurse into children
                node.children = clearDeletedFolder(node.children);
            }
            return node;
        });
    }
    const updatedFileSystem = clearDeletedFolder(fileSystem);
    return updatedFileSystem;
}

function deleteNodeAndMoveFiles(nodeId, fileSystem) {
    let filesToMove = [];

    // This recursive function collects file nodes and removes the specified node
    function collectFilesAndDeleteNode(nodes, parentId = null) {
        return nodes.filter((node) => {
            // Collect file nodes before deletion or moving
            if (node.type === "file" && (node.id === nodeId || parentId !== null)) {
                filesToMove.push({ ...node, parentId: "subworkspace-1-2" });
                // If the node itself is a file and matches nodeId, don't keep it in the array
                if (node.id === nodeId) {
                    return false;
                }
            }

            // If the node has children, recurse and update children
            if (node.children) {
                node.children = collectFilesAndDeleteNode(
                    node.children,
                    node.id === nodeId ? "subworkspace-1-2" : parentId
                );
            }

            // If the current node is the one to delete (and not a file), filter it out
            return !(node.id === nodeId && node.type !== "file");
        });
    }

    // Function to add collected files to the 'Deleted' folder
    function addFilesToDeletedFolder(nodes) {
        return nodes.map((node) => {
            if (node.id === "subworkspace-1-2") {
                // Ensure the Deleted folder's children array is initialized
                if (!node.children) {
                    node.children = [];
                }
                // Add all collected files to this folder
                node.children = [...node.children, ...filesToMove];
            } else if (node.children) {
                // Recurse into children
                node.children = addFilesToDeletedFolder(node.children);
            }
            return node;
        });
    }

    // First, collect files to move and remove the specified node
    const updatedFileSystem = collectFilesAndDeleteNode(fileSystem);
    // Then, move the collected files to the 'Deleted' folder
    return addFilesToDeletedFolder(updatedFileSystem);
}

function moveFiles(fileId, newParentId, fileSystem, sorting) {
    let fileToMove = null;
    let foundInNewParent = false;

    // Helper function to deeply clone the file system to avoid direct mutation
    function cloneFileSystem(fs) {
        return JSON.parse(JSON.stringify(fs));
    }

    // Find and remove the file from its current location, if applicable
    function findAndRemoveFile(nodes) {
        return nodes.filter((node) => {
            if (node.id === fileId) {
                fileToMove = { ...node }; // Successfully found and setting the file to move
                return false; // Remove this node from the current list
            }
            if (node.children) {
                node.children = findAndRemoveFile(node.children); // Recursive call
            }
            return true; // Keep this node in the list
        });
    }

    // Check if the file is already in the new parent
    function checkFileInNewParent(nodes) {
        nodes.forEach((node) => {
            if (node.id === newParentId && node.children) {
                foundInNewParent = node.children.some((child) => child.id === fileId);
                if (foundInNewParent) return;
            }
            if (node.children && !foundInNewParent) {
                checkFileInNewParent(node.children);
            }
        });
    }

    // Clone the file system before making changes
    let clonedFileSystem = cloneFileSystem(fileSystem);

    // Check if the file already exists in the new parent
    checkFileInNewParent(clonedFileSystem);
    if (foundInNewParent) {
        console.log("File is already in the new parent.");
        return clonedFileSystem; // Return the unchanged file system
    }

    // If not found in new parent, proceed with find and remove
    clonedFileSystem = findAndRemoveFile(clonedFileSystem);

    // After searchAndRemoveFile execution, fileToMove should be set if the file was found
    if (!fileToMove) {
        console.log("File not found or already moved");
        return clonedFileSystem; // Return the original structure as a fallback
    }

    // Add the file to its new location
    // This assumes addNode is modified to be idempotent as well
    return addNode(newParentId, fileToMove, clonedFileSystem, sorting);
}

function addWorkspace(fileSystem, setNewObjectId, sorting) {
    capture_event(EVENTS.FOLDER_CREATED);
    const newId = uuidv4();
    const date = new Date();
    const newWorkspace = {
        id: newId,
        name: "Unnamed Folder",
        parentId: null,
        date: convertDateToString(date),
        sorting: "date",
        type: "workspace",
        open: false,
        children: [],
    };

    setNewObjectId(newId);
    const updatedFileSystem = [...fileSystem, newWorkspace];
    const sortedNewFileSystem = sortChildrenInFileSystem(
        updatedFileSystem,
        null,
        oppositeComparator(sorting)
    );
    return sortedNewFileSystem;
}

function addSubworkspaceToWorkspace(workspaceId, fileSystem, setNewObjectId, sorting) {
    capture_event(EVENTS.FOLDER_CREATED);
    const newId = uuidv4();
    // Function to recursively search and update the file system
    function addSubWorkspace(nodes) {
        return nodes.map((node) => {
            if (node.id === workspaceId) {
                // Found the target workspace, add the new subworkspace to its children
                const date = new Date();
                const newSubworkspace = {
                    id: newId,
                    name: "Unnamed Folder",
                    parentId: workspaceId,
                    date: convertDateToString(date),
                    sorting: "date",
                    type: "subworkspace",
                    open: false,
                    children: [],
                };
                const updatedNode = {
                    ...node,
                    children: [...(node.children || []), newSubworkspace],
                };
                return updatedNode;
            } else if (node.children) {
                // Current node is not the target, but it has children, search recursively
                const updatedChildren = addSubWorkspace(node.children);
                return { ...node, children: updatedChildren };
            }
            // Current node is neither the target nor has children, return it unchanged
            return node;
        });
    }

    // Recursively update the file system
    const updatedFileSystem = addSubWorkspace(fileSystem);
    setNewObjectId(newId);
    const sortedNewFileSystem = sortChildrenInFileSystem(
        updatedFileSystem,
        workspaceId,
        oppositeComparator(sorting)
    );
    return sortedNewFileSystem;
}

function addFolderToSubworkspace(subworkspaceId, fileSystem, setNewObjectId, sorting) {
    const newId = uuidv4();

    // Function to recursively search and update the file system
    function addFolder(nodes) {
        return nodes.map((node) => {
            if (node.id === subworkspaceId) {
                // Found the target workspace, add the new subworkspace to its children
                const date = new Date();
                const newFolder = {
                    id: newId,
                    name: "Unnamed Folder",
                    parentId: subworkspaceId,
                    date: convertDateToString(date),
                    sorting: "date",
                    type: "folder",
                    open: false,
                    children: [],
                };
                const updatedNode = { ...node, children: [...(node.children || []), newFolder] };
                return updatedNode;
            } else if (node.children) {
                // Current node is not the target, but it has children, search recursively
                const updatedChildren = addFolder(node.children);
                return { ...node, children: updatedChildren };
            }
            // Current node is neither the target nor has children, return it unchanged
            return node;
        });
    }

    // Recursively update the file system
    const updatedFileSystem = addFolder(fileSystem);
    setNewObjectId(newId);
    const sortedNewFileSystem = sortChildrenInFileSystem(
        updatedFileSystem,
        subworkspaceId,
        oppositeComparator(sorting)
    );
    return sortedNewFileSystem;
}

function updateOpenInFileSystem(id, isOpen, fileSystem) {
    const updateName = (nodes) =>
        nodes.map((node) => {
            if (node.id === id) {
                return { ...node, open: isOpen };
            } else if (node.children) {
                return { ...node, children: updateName(node.children) };
            }
            return node;
        });

    const updatedFileSystem = updateName(fileSystem);
    return updatedFileSystem;
}

function updateNameInFileSystem(id, newName, fileSystem) {
    let parentId = null; // This will hold the parent ID of the node with the specified 'id'
    let sorting = "date";

    const updateName = (nodes, parent = null) =>
        nodes.map((node) => {
            if (node.id === id) {
                parentId = parent === null ? null : parent.id;
                sorting =
                    parent === null ? oppositeComparator(fileSystem[0].sorting) : parent.sorting;
                return { ...node, name: newName };
            } else if (node.children) {
                return { ...node, children: updateName(node.children, node) }; // Pass current node's id as 'parent' for the next call
            }
            return node;
        });

    const updatedFileSystem = updateName(fileSystem);

    //console.log(parentId);
    //console.log(sorting);
    const sortedNewFileSystem = sortChildrenInFileSystem(
        updatedFileSystem,
        parentId,
        oppositeComparator(sorting)
    );

    return sortedNewFileSystem;
}

const throttledCaptureEvent = throttle(
    (searchString) => {
        if (searchString.length > 1) {
            capture_event(EVENTS.FILEMANAGER_SEARCHED, {
                search_string: searchString,
            });
        }
    },
    1000,
    { trailing: true, leading: false }
);

function findNodesByName(searchString, fileSystem) {
    throttledCaptureEvent(searchString);

    let results = [];

    // Recursive function to search through each node
    function searchNodes(nodes) {
        nodes.forEach((node) => {
            // Check if the node's name contains the searchString
            if (
                node.type === "file" &&
                node.name &&
                node.name.toLowerCase().includes(searchString.toLowerCase())
            ) {
                results.push(node); // Add the matching node to the results array
            }

            // If the node has children, search through them recursively
            if (node.children && node.children.length > 0) {
                searchNodes(node.children);
            }
        });
    }

    searchNodes(fileSystem); // Start the search with the root of the file system
    return results;
}

function sortChildrenInFileSystem(fileSystem, id, comparator) {
    const sortTopLevel = () => {
        let firstElement = fileSystem[0];
        let elementsToSort = fileSystem.slice(1);
        const thresholdDate = new Date("2024-04-01");
        if (comparator === "name") {
            elementsToSort.sort((a, b) => a.name.localeCompare(b.name));
        } else if (comparator === "date") {
            elementsToSort.sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);

                // Both dates are before the threshold date
                if (dateA < thresholdDate && dateB < thresholdDate) {
                    return dateA - dateB; // Sort ascending (reverse since we're before the threshold)
                }
                // General case, sort descending
                return dateB - dateA;
            });
        }

        firstElement.sorting = comparator;

        return [firstElement, ...elementsToSort];
    };

    const sortChildren = (node) => {
        if (node.id === id) {
            if (id === "subworkspace-1-1" || id === "subworkspace-1-2") {
                return { ...node, children: node.children };
            }
            // Determine the new comparator for the next sort operation
            const newComparator = comparator === "name" ? "date" : "name";
            // Perform sorting based on the current comparator
            if (comparator === "name") {
                node.children.sort((a, b) => b.name.localeCompare(a.name));
            } else if (comparator === "date") {
                node.children.sort((a, b) => new Date(a.date) - new Date(b.date));
            }

            // Return node with updated sorting setting
            return { ...node, sorting: newComparator, children: node.children };
        } else if (node.children) {
            // Recursively process children to find the target node
            return { ...node, children: node.children.map((child) => sortChildren(child)) };
        }
        return node;
    };

    // Map over the file system to apply sorting to the target node
    if (id === null) {
        const sortedTopLevel = sortTopLevel();
        return sortedTopLevel;
    } else {
        return fileSystem.map((node) => sortChildren(node));
    }
}

const FileManagerNew = ({
    fileSystem,
    setFileSytem,
    isVisible,
    onClose,
    onFileSelect,
    handleUploadButtonClick,
    handleFileChange,
    handleUploadDefault,
    trigger_save_filesystem,
    saveFilesystem,
    setIsUploading,
    topComparator,
    setTopComparator,
}) => {
    const [searchedFiles, setSearchedFiles] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [newObjectId, setNewObjectId] = useState("");

    useEffect(() => {
        if (newObjectId) {
            const element = document.getElementById(newObjectId);
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "nearest" });

                let event = new MouseEvent("dblclick", {
                    bubbles: true,
                    cancelable: true,
                });
                // Dispatch the event to the element
                element.dispatchEvent(event);
            }

            const element_input_form = document.getElementById(newObjectId + 1);
            if (element_input_form) {
                let event = new MouseEvent("dblclick", {
                    bubbles: true,
                    cancelable: true,
                });
                // Dispatch the event to the element
                element_input_form.dispatchEvent(event);
            }
        }
    }, [newObjectId]); // Depend on newObjectId

    const handleNameChange = (e) => {
        setSearchValue(e.target.value);
    };

    useEffect(() => {
        if (searchValue !== "") {
            const results = findNodesByName(searchValue, fileSystem);
            setSearchedFiles(results);
        } else {
            setSearchedFiles([]); // Clear results when searchValue is empty
        }
    }, [searchValue, fileSystem]);

    if (!isVisible) return null;
    //const updatedFileSystem = addNode('folder-2-2-1', newFile, initialValue);
    //console.log(updatedFileSystem);
    //const nodeIdToDelete = 'file-2-2-1-2';
    //const updatedFileSystem = moveFiles(nodeIdToDelete,'subworkspace-1-2', initialValue);

    return (
        <div className={styles.overlay}>
            {fileSystem === "" ? (
                <div className={styles.window}>
                    <div className={styles.preparingfilemanager}>
                        <ClipLoader color="#256EFF" />
                        <p>Preparing Files...</p>
                    </div>
                </div>
            ) : (
                <div className={styles.window}>
                    <div className={styles.toolbar}>
                        <div className={styles.title}>Files</div>
                        <div className={styles.controlbar}>
                            <div
                                className={styles.button}
                                onClick={() => {
                                    handleUploadButtonClick();
                                }}>
                                <span>
                                    <Icon name="UploadIcon" />
                                </span>
                                <input
                                    id="home"
                                    type="file"
                                    style={{ display: "none" }}
                                    accept=".pdf,.docx,.DOCX,.doc,.DOC,.pptx,.PPTX,.ppt,.PPT,.odt,.ODT"
                                    onChange={handleUploadDefault}
                                />
                            </div>
                            <div
                                className={styles.button}
                                onClick={() => {
                                    setFileSytem(
                                        addWorkspace(fileSystem, setNewObjectId, topComparator)
                                    );
                                }}>
                                <span>
                                    <Icon name="AddFolderIcon" />
                                </span>
                            </div>
                            <div className={styles.button}>
                                <span
                                    style={{ transform: "translateY(1px)" }}
                                    onClick={() => {
                                        setFileSytem(
                                            sortChildrenInFileSystem(
                                                fileSystem,
                                                null,
                                                topComparator
                                            )
                                        );
                                        setTopComparator(oppositeComparator(topComparator));
                                    }}>
                                    {topComparator === "date" ? <SortIcon /> : <AlphabetIcon />}
                                </span>
                            </div>
                            <div className={styles.findButton}>
                                <div className={styles.findButtonElements}>
                                    <span className={styles.findButtonIcon}>
                                        <Icon name="MagnifyingGlassIcon" />
                                    </span>
                                    <div className={styles.findButtonForm}>
                                        <input
                                            className={styles.findButtonFormInput}
                                            placeholder="Search"
                                            value={searchValue}
                                            onChange={handleNameChange}></input>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={styles.button}
                                onClick={() => {
                                    trigger_save_filesystem();
                                    onClose();
                                }}>
                                <span style={{ transform: "translateY(-2px)" }}>&times;</span>
                            </div>
                        </div>
                    </div>
                    {searchValue !== "" ? (
                        <div className={styles.fileSearchWindow}>
                            {searchedFiles.map((node) => (
                                <FileViewOnly
                                    key={node.id}
                                    id={node.id}
                                    title={node.name}
                                    onFileSelect={onFileSelect}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className={styles.filemanager}>
                            <div className={styles.wrapper}>
                                {renderNodes(
                                    fileSystem,
                                    fileSystem,
                                    setFileSytem,
                                    handleUploadButtonClick,
                                    handleFileChange,
                                    onFileSelect,
                                    trigger_save_filesystem,
                                    setIsUploading,
                                    saveFilesystem,
                                    setNewObjectId
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default FileManagerNew;
