import React, { useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { createPortal } from "react-dom";
import Icon from "../Icon";
import styles from "./Toolbar.module.css";

const Popper = ({ children }) => {
    return createPortal(children, document.body);
};

const BurgerMenuItem = ({ children, ...props }) => {
    return (
        <li className="menu-item" {...props}>
            {children}
        </li>
    );
};

const BurgerMenu = ({ children, open, onSelect }) => {
    const menuItems = React.Children.map(children, (child) => {
        if (child.type !== BurgerMenuItem) {
            throw new Error(`Not a valid BurgerMenuItem: ${child.type}`);
        }

        return React.cloneElement(child);
    });

    if (!open) return null;

    return (
        <Popper>
            <ul className={styles.burgerMenu}>{menuItems}</ul>
        </Popper>
    );
};

export const BurgerNavi = ({ onOpenProfile, onUpgradePlan, onSendFeedback }) => {
    const [open, setIsOpen] = useState(false);
    return (
        <ClickAwayListener
            onClickAway={(event) => {
                setIsOpen(false);
            }}>
            <button className={styles.btn_burgernNav} onClick={() => setIsOpen(!open)}>
                <Icon name="BurgerNavIcon" />
                <BurgerMenu open={open} onClick={() => setIsOpen(false)}>
                    <BurgerMenuItem onClick={() => onOpenProfile()}>open my Profile</BurgerMenuItem>
                    <BurgerMenuItem onClick={() => onUpgradePlan()}>Upgrade Plan</BurgerMenuItem>
                    <BurgerMenuItem onClick={() => onSendFeedback()}>send Feedback</BurgerMenuItem>
                </BurgerMenu>
            </button>
        </ClickAwayListener>
    );
};
