export default function IconSummary() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <path
                d="M19 13V15C19 17.8284 19 19.2426 18.1213 20.1213C17.2426 21 15.8284 21 13 21H11C8.17157 21 6.75736 21 5.87868 20.1213C5 19.2426 5 17.8284 5 15V9C5 6.17157 5 4.75736 5.87868 3.87868C6.75736 3 8.17157 3 11 3H12"
                stroke="currentColor"
                strokeLinecap="round"
            />
            <path d="M9 13L15 13" stroke="currentColor" strokeLinecap="round" />
            <path d="M9 9L13 9" stroke="currentColor" strokeLinecap="round" />
            <path d="M9 17L13 17" stroke="currentColor" strokeLinecap="round" />
            <path
                d="M14 6.5C16 5 16 5 17 2C18 5 18 5 20 6.5C18 8 18 8 17 10.5C16 8 16 8 14 6.5Z"
                stroke="currentColor"
                strokeLinejoin="round"
            />
        </svg>
    );
}
