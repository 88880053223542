import { useCheckout } from "@memberstack/react";
import React from "react";
import { capture_event, EVENTS } from "../../utils/analytics";
import styles from "./plancard.module.css";
function splitByNewLine(text) {
    return text.split("\n").map((part, index, array) => (
        <span key={index}>
            {part}
            {index !== array.length - 1 && <br />}
        </span>
    ));
}

function PlanCard({ plan }) {
    const checkout = useCheckout();

    const handleCheckout = (price_id) => {
        capture_event(EVENTS.PLAN_UPGRADE_CLICKED, {
            price_id: price_id,
        });
        checkout({ priceId: price_id });
    };

    function NewlineText(props) {
        const text = props.text;
        const newText = text.split("\n").map((str, index) => <p key={index}>{str}</p>);
        return newText;
    }

    return (
        <div className={styles.cardItem}>
            {" "}
            {/* Corresponds to card-item-4 in the image */}
            <div className={styles.cardItemContent}>
                <div className={styles.pricingTitle}>
                    {" "}
                    {/* "pricing-title" and "Unlimited" in the image */}
                    <NewlineText text={plan.title} />
                </div>
                <div className={styles.pricingMainContent}>
                    {" "}
                    {/* "pricing-main-content" in the image */}
                    <div className={styles.productItemPrice}>
                        <span className={styles.currencySymbol}>{plan.currencySymbol}</span>
                        {plan.price}
                    </div>
                    <div className={styles.pricingItemPriceDesc}>
                        {" "}
                        {/* "pricing-item-price-desc" in the image */}
                        {splitByNewLine(plan.duration)}
                    </div>
                </div>
                <div className={styles.pricingItemDescription}>
                    {" "}
                    {/* "pricing-item-description-3" in the image */}
                    {plan.description}
                </div>
                <div className={styles.pricingItemButtonHolder}>
                    <button
                        onClick={() => handleCheckout(plan.price_id)}
                        className={styles.checkoutButton}>
                        Upgrade now
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PlanCard;
