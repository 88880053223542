export const languages = {
    DE: "German",
    EN: "English",
};

export const flashcardModes = {
    QA_PAIRS: "Q&A Cards",
    SINGLE_CHOICE: "Single-Choice Cards",
    MULTIPLE_CHOICE: "Multiple-Choice Cards",
    NONE: "More coming...",
};

export const summaryModes = {
    "Bullets - detailed": "DETAILED_IN_BULLET_POINTS",
    "Bullets - short": "SHORT_IN_BULLET_POINTS",
    "Text - short": "SHORT_IN_TEXT",
};
