export default function IconFlashCards() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <path
                d="M18.5 9.5V8.7C18.5 7.57989 18.5 7.01984 18.282 6.59202C18.0903 6.21569 17.7843 5.90973 17.408 5.71799C16.9802 5.5 16.4201 5.5 15.3 5.5H7.7C6.57989 5.5 6.01984 5.5 5.59202 5.71799C5.21569 5.90973 4.90973 6.21569 4.71799 6.59202C4.5 7.01984 4.5 7.57989 4.5 8.7V12.3C4.5 13.4201 4.5 13.9802 4.71799 14.408C4.90973 14.7843 5.21569 15.0903 5.59202 15.282C6.01984 15.5 6.57989 15.5 7.7 15.5H13.5"
                stroke="currentColor"
                strokeLinecap="round"
            />
            <path d="M7.5 12.5H11.5" stroke="currentColor" strokeLinecap="round" />
            <path d="M7.5 8.5H14.5" stroke="currentColor" strokeLinecap="round" />
            <path
                d="M16.645 12.2696C16.6508 12.2601 16.6565 12.2507 16.6622 12.2413C16.8348 11.9567 16.9884 11.7034 17.124 11.5311C17.1938 11.4425 17.2873 11.3373 17.4071 11.2606C17.5416 11.1745 17.734 11.1109 17.952 11.1718C18.1699 11.2328 18.3014 11.387 18.3718 11.5303C18.4344 11.658 18.4598 11.7965 18.4735 11.9084C18.5001 12.1261 18.5 12.4223 18.5 12.7551C18.5 12.7661 18.5 12.7772 18.5 12.7883V13.5546C18.8056 13.5547 19.0625 13.5563 19.2705 13.5717C19.5038 13.589 19.7428 13.6266 19.954 13.7443C20.4465 14.0188 20.7435 14.5463 20.7228 15.1097C20.7139 15.3514 20.6221 15.5752 20.5158 15.7837C20.4099 15.9914 20.2575 16.2425 20.0737 16.5456L20.0635 16.5624L19.355 17.7304M16.645 12.2696L19.355 17.7304M16.645 12.2696L16.645 12.2696L15.9365 13.4376L15.9263 13.4544C15.7425 13.7575 15.5901 14.0086 15.4842 14.2163C15.3779 14.4248 15.2861 14.6486 15.2772 14.8903C15.2565 15.4537 15.5535 15.9812 16.046 16.2557C16.2572 16.3734 16.4962 16.411 16.7295 16.4283C16.9375 16.4436 17.1944 16.4453 17.5 16.4454V17.2117L17.5 17.2449C17.5 17.5777 17.4999 17.8739 17.5265 18.0916C17.5402 18.2035 17.5656 18.342 17.6282 18.4697C17.6986 18.613 17.8301 18.7672 18.048 18.8282C18.266 18.8891 18.4584 18.8255 18.5929 18.7394C18.7127 18.6627 18.8062 18.5575 18.876 18.4689C19.0116 18.2966 19.1652 18.0433 19.3378 17.7587L19.3422 17.7514L19.355 17.7304M16.645 12.2696L19.355 17.7304"
                stroke="currentColor"
            />
        </svg>
    );
}
