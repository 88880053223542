import styles from "./App.module.css";

import { v4 as uuidv4 } from "uuid";
import Toolbar from "./components/Toolbar/Toolbar";

import FileManagerNew from "./components/FileManager/FileManagerNew";
import DownloadModal from "./components/OverlayModal/DownloadModal";
import OverlayModal from "./components/OverlayModal/OverlayModal";
import ReactPDFViewer from "./components/PdfViewer/ReactPDFViewer";
import PricingModal from "./components/PricingModal/PricingModal";
import StartLayer from "./components/StartLayer/StartLayer";
import TinyEditor from "./components/SummaryMode/TinyEditor";
import ChatRoom from "./components/TutorMode/ChatRoom";

// import module react and hooks "useEffect", "useSate", ...
import React, { useEffect, useRef, useState } from "react";

// handle Request-Response-Stuff with axios
import axios from "axios";

import BarLoader from "react-spinners/BarLoader";
import ClipLoader from "react-spinners/ClipLoader";
import AuthPage from "./components/AuthPage/AuthPage";

import { UAParser } from "ua-parser-js";

//HANDOVER: why is this in curly-braces?
// this imports the hook "MemberstackProvider"
import { MemberstackProvider, useMemberstackModal } from "@memberstack/react";

import { capture_event, EVENTS } from "./utils/analytics";
import { config } from "./utils/config";
import {
    wake_up_convert_to_pdf,
    wake_up_document_processor,
    wake_up_text_extract,
} from "./utils/wake_up_functions";

import ErrorModal from "./components/ErrorModal";
import {
    checkAllFlashcardsCanStudied,
    getFirstFlashcardNotReady,
} from "./components/Flashcard/FlashcardFunctions";
import FlashcardContainer from "./components/FlashcardsMode/FlashcardContainer";
import GenerateFlashcardsModal from "./components/FlashcardsMode/GenerateFlashcardsModal";
import InfoBanner from "./components/InfoBanner";
import jokes from "./components/Jokes";
import FeedbackModal from "./components/OverlayModal/FeedbackModal";
import GenerateSummaryModal from "./components/SummaryMode/GenerateSummaryModal";
import ToolbarBottom from "./components/ToolbarBottom/ToolbarBottom";
import UpgradeModal from "./components/UpgradeModal/UpgradeModal";

const APP_MODE_TUTOR = "tutor";
const APP_MODE_SUMMARY = "summary";
const APP_MODE_CARDS = "cards";

function Learnboost({ setUserId, user_id, setIsAuthenticated, isPaidUser, plan, language }) {
    // this is the "mobile-optimization" to hard-set the view-sizes
    // for mobile-phones and tables
    // executed with a timeout because it's somehow not working otherwise
    //setTimeout(updateViewportMetaTag, 1000);

    const [isUploading, setisUploading] = useState(false);
    const [hasUploaded, sethasUploaded] = useState(false);
    const [isSummarizing, setisSummarizing] = useState(false);
    const [hasSummarized, sethasSummarized] = useState(false);
    const [isGeneratingFlashCards, setIsGeneratingFlashCards] = useState(false);
    const [isExportingFlashCards, setIsExportingFlashCards] = useState(false);
    const [hasFlashCards, setHasFlashCards] = useState(false);
    const [flashcardStatus, setFlashCardStatus] = useState("complete");
    const [flashcardLogs, setFlashCardLogs] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState("German");
    const [appMode, setAppMode] = useState(APP_MODE_TUTOR);
    const [pdfURL, setPDFURL] = useState("");
    const [document_id, setDocumentId] = useState("");
    const [fileNameId, setFileNameId] = useState(1);
    const [chat, setChat] = useState([]);

    // ****************************************************************************************************************
    // summary
    // ****************************************************************************************************************
    const [loadingSummaryText, setLoadingSummaryText] = useState("Preparing document for summary");
    const [summaryStatus, setSummaryStatus] = useState("complete");
    const [summary, setSummary] = useState("");
    const [initial_summary, setInitialSummary] = useState(""); // used to track changes for autosave
    const [summary_title, setSummaryTitle] = useState("");
    const [isSavingSummary, setIsSavingSummary] = useState(false);
    const [summaryId, setSummaryId] = useState(1);

    const hasSummarizedRef = useRef(hasSummarized);
    useEffect(() => {
        hasSummarizedRef.current = hasSummarized;
    }, [hasSummarized]);

    const summaryRef = useRef(summary);
    useEffect(() => {
        summaryRef.current = summary;
    }, [summary]);

    const initialSummaryRef = useRef(initial_summary);
    useEffect(() => {
        initialSummaryRef.current = initial_summary;
    }, [initial_summary]);
    // ****************************************************************************************************************

    // ****************************************************************************************************************
    // flashcards
    // ****************************************************************************************************************
    const [flashcards, setFlashCards] = useState("");
    const flashcardsRef = useRef("");

    const getFlashcardsRefMap = () => {
        if (!flashcardsRef.current) {
            flashcardsRef.current = new Map();
        }
        return flashcardsRef.current;
    };

    const [flashcards_title, setFlashCardsTitle] = useState("");
    const [isStudyModeActive, setIsStudyModeActive] = useState(false);
    const [numberOfRepetitions, setNumberOfRepetitions] = useState(3);
    const [loadingFlashcardsText, setLoadingFlashcardsText] = useState(
        "Preparing document for generating flashcards"
    );
    const [showRegenerateCardsModal, setShowRegenerateCardsModal] = useState(false);
    // ****************************************************************************************************************

    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
    const [isShowingCheckIcon, setIsShowingCheckIcon] = useState(false); //shows check Icon after summary is saved

    const [fileSystem, setFileSytem] = useState("");
    const [initalFileSystem, setInitialFileSystem] = useState("");

    const [currentAreas, setCurrentAreas] = useState([]);
    const [currentPageReference, setCurrentPageReference] = useState(0);

    const [loadingUpload, setLoadingUpload] = useState("Uploading document");

    const [uploadProgess, setUploadProgress] = useState(0);

    const [isFileManagerVisible, setFileManagerVisible] = useState(false);
    const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
    const [feedbackMode, setFeedbackMode] = useState("standard");
    const [selectedUploadFile, setSelectedUploadFile] = useState(null);

    const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
    const [downloadLink, setDownloadLink] = useState("");

    const [bannerData, setBannerData] = useState({ visible: false, message: "" });

    const showInfoBanner = (message, color = "red") => {
        setBannerData({ visible: true, message: message, color: color });
    };

    const [isResetModalOpen, setIsResetModalOpen] = useState(false);
    const [joke, setJoke] = useState(jokes[0]);

    const toggleResetModal = () => {
        setIsResetModalOpen(!isResetModalOpen);
    };

    const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

    const [isChangingDocument, setIsChangingDocument] = useState(false);

    // Determined by ISO 3166-1 alpha-2
    const [countryCode, setCountryCode] = useState("");

    const [errorInfo, setErrorInfo] = useState({ isVisible: false, message: "", statusCode: "" });

    const showError = (message, statusCode) =>
        setErrorInfo({ isVisible: true, message: message, statusCode: statusCode });
    const hideError = () => setErrorInfo({ isVisible: false, message: "", statusCode: "" });

    const MAX_UPLOAD_SIZE_FREE_IN_BYTES = 25000000;
    const MAX_UPLOAD_SIZE_PAID_IN_BYTES = 75000000;

    const [topComparator, setTopComparator] = useState("date");

    const welcomeMessage =
        "Welcome! I'm thrilled to assist you in Tutor AI mode. Here, you can delve into specific queries about your document, whether it's breaking down complex information or simplifying concepts for better understanding. Should you require detailed summaries, feel free to switch to the Summarize AI mode. Remember, I'm here to guide you using only the information provided in your document unless you allow me to use external information. Let's embark on this learning journey together! <sup>1</sup>";

    const toggleCheckMark = () => {
        setIsShowingCheckIcon(true);
        setTimeout(() => {
            setIsShowingCheckIcon(false);
        }, 3000);
    };

    const [pdfViewerVisible, setPdfViewerVisible] = useState(true);

    const togglePdfViewer = () => {
        setPdfViewerVisible(!pdfViewerVisible);
        console.log("toggle pdf viewer to ", !pdfViewerVisible);
    };

    const renderRightSideMode = () => {
        if (window.innerWidth < 768) {
            return !pdfViewerVisible;
        } else {
            return true;
        }
    };

    const handleRegenerateFlashcards = () => {
        if (document_id === "") {
            return;
        }
        setShowRegenerateCardsModal(true);
    };

    const getUserDeviceDetails = () => {
        const parser = new UAParser();
        const result = parser.getResult();
        const browserName = result.browser.name;
        const osName = result.os.name;
        const device = parser.getDevice();

        const deviceFormatted = JSON.parse(
            JSON.stringify(device, (key, value) => (value === undefined ? "Desktop" : value))
        );

        return { browser: browserName, os: osName, device: deviceFormatted };
    };

    function convertDateToString(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }

    // useEffect is executed everytime the dependency-array changes (the second parameter)
    // if the dep-array is empty useEffect will be called once when the page is loaded and never again
    useEffect(() => {
        get_country_from_ip();
        fetchDocuments();
    });

    const fileSystemRef = useRef(fileSystem);

    useEffect(() => {
        fileSystemRef.current = fileSystem;
    }, [fileSystem]);

    const initialFileSystemRef = useRef(initalFileSystem);

    useEffect(() => {
        initialFileSystemRef.current = initalFileSystem;
    }, [initalFileSystem]);

    const isChangingDocumentRef = useRef(isChangingDocument);

    useEffect(() => {
        isChangingDocumentRef.current = isChangingDocument;
    }, [isChangingDocument]);

    const documentIdRef = useRef(document_id);

    useEffect(() => {
        documentIdRef.current = document_id;
    }, [document_id]);

    const userIdRef = useRef(user_id);

    useEffect(() => {
        userIdRef.current = user_id;
    }, [user_id]);

    useEffect(() => {
        const interval = setInterval(() => {
            trigger_save_filesystem();
        }, 5000); // 5000 milliseconds = 5 seconds

        // Clear the interval when the component unmounts
        return () => clearInterval(interval);
    });

    const trigger_save_filesystem = async () => {
        const isFilesystemEqual =
            JSON.stringify(fileSystemRef.current) === JSON.stringify(initialFileSystemRef.current);

        if (!isFilesystemEqual) {
            setInitialFileSystem(fileSystemRef.current);
            console.log("saving filemanager");
            await saveFileSystem();
        }
    };

    const saveFileSystem = async () => {
        try {
            const response = await axios.get(config.api_urls.upload_filesystem_as_json, {
                params: {
                    user_id: user_id,
                },
            });
            const presignedUrl = response.data.presigned_url;
            //console.log(presignedUrl)

            const fileSystemJson = JSON.stringify(fileSystemRef.current);
            const file = new Blob([fileSystemJson], { type: "application/json" });

            await uploadFileToS3(presignedUrl, file);
            console.log("successfully saved filemanager");
        } catch (error) {
            console.log(error);
        }
    };

    // SUMMARY_EDITOR
    const trigger_save_summary = async () => {
        console.log("trigger_save_summary called");
        if (hasSummarizedRef.current === false || isChangingDocumentRef === true) {
            return;
        }
        const isSummaryEqual =
            JSON.stringify(summaryRef.current) === JSON.stringify(initialSummaryRef.current);

        if (!isSummaryEqual) {
            console.log("summary is not equal, saving summary");
            setInitialSummary(summaryRef.current);
            setIsSavingSummary(true);
            console.log("saving summary...");
            await saveSummary(documentIdRef.current);
            console.log("summary updated");
            setIsSavingSummary(false);
            toggleCheckMark();
        }
    };
    // SUMMARY_EDITOR
    const saveSummary = async (document_id) => {
        try {
            const response = await axios.get(config.api_urls.upload_summary_as_json, {
                params: {
                    user_id: user_id,
                    document_id: document_id,
                },
            });
            const presignedUrl = response.data.presigned_url;
            console.log(presignedUrl);

            const summaryJson = JSON.stringify(summaryRef.current);
            const file = new Blob([summaryJson], { type: "application/json" });

            await uploadFileToS3(presignedUrl, file);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchDocuments = async (counter = 1) => {
        try {
            if (fileSystem === "") {
                const response = await axios.get(config.api_urls.get_filemanager, {
                    params: { user_id },
                });
                const filemanagerdownloadurl = response.data;

                const s3Response = await axios.get(filemanagerdownloadurl);
                const newFileSystem = s3Response.data;
                if (!newFileSystem[0].sorting && counter === 1) {
                    await updateFileManager();
                    await fetchDocuments(counter++);
                    return;
                }
                console.log("Filemanager:", newFileSystem);
                setFileSytem(newFileSystem);
                setInitialFileSystem(newFileSystem);
                setTopComparator(newFileSystem[0]?.sorting === "date" ? "name" : "date");
                console.log("Sorting:", newFileSystem[0]?.sorting);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateFileManager = async () => {
        try {
            await axios.get(config.api_urls.update_filemanager, { params: { user_id: user_id } });
        } catch (error) {
            console.log(error);
        }
    };

    const get_pdf_url = async (document_id) => {
        try {
            const response = await axios.get(config.api_urls.get_pdf_url, {
                params: { document_id: document_id },
            });
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const submitSupportRequest = async (message, statusCode) => {
        try {
            return await axios.get(config.api_urls.submit_support_request, {
                params: {
                    user_id: user_id,
                    document_id: document_id,
                    statusCode: statusCode,
                    message: message,
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    const toggleFileManager = () => {
        console.log("toggle filemanager");
        fetchDocuments();
        if (isUploading !== true) {
            setFileManagerVisible(!isFileManagerVisible);
            if (!isFileManagerVisible) {
                capture_event(EVENTS.FILEMANAGER_OPENED);
            } else {
                capture_event(EVENTS.FILEMANAGER_CLOSED);
            }
        } else {
            showInfoBanner("You can't open the filemanager while uploading a document.");
        }
    };

    const toggleFeedbackModal = () => {
        if (!isFeedbackModalVisible) {
            capture_event(EVENTS.FEEDBACK_MODAL_OPENED);
        } else {
            capture_event(EVENTS.FEEDBACK_MODAL_CLOSED);
        }
        setIsFeedbackModalVisible(!isFeedbackModalVisible);
    };

    const toggleDownloadModal = () => {
        setIsDownloadModalVisible(!isDownloadModalVisible);
    };

    const toggleUpgradeModal = () => {
        console.log("upgrade modal");
        if (!isUpgradeModalOpen) {
            capture_event(EVENTS.PRICING_MODAL_OPENED);
        } else {
            capture_event(EVENTS.PRICING_MODAL_CLOSED);
        }
        setIsUpgradeModalOpen(!isUpgradeModalOpen);
    };

    const toggleStudyMode = () => {
        if (isChangingDocument || document_id === "" || hasFlashCards === false) {
            setIsStudyModeActive(false);
            return;
        }

        if (!isStudyModeActive && !checkAllFlashcardsCanStudied(flashcards)) {
            console.log("flashcards are not ready");
            showInfoBanner(
                "Please ensure all cards have proper questions and Answers. also Multiple-Choice Cards should have at least 1 correct answer",
                "blue"
            );
        } else {
            console.log("flashcards are ready");
        }

        setIsStudyModeActive(!isStudyModeActive);

        if (!isStudyModeActive) {
            capture_event(EVENTS.FLASHCARDS_STUDYMODE_STARTED, {
                document_id: document_id,
            });
        } else {
            capture_event(EVENTS.FLASHCARDS_STUDYMODE_STOPPED, {
                document_id: document_id,
            });
        }
    };

    const handleFileSelect = async (document_id, file_name) => {
        capture_event(EVENTS.DOCUMENT_OPENED, {
            document_id: document_id,
            file_name: file_name,
        });
        console.log("Selected file id:", document_id);
        console.log("Selected file name", file_name);
        setIsChangingDocument(true);
        setIsStudyModeActive(false);
        toggleFileManager();
        await trigger_save_summary();
        setDocumentId(document_id);
        setFileNameId(fileNameId + 1);
        const file_url = await get_pdf_url(document_id);
        setPDFURL(file_url);
        sethasUploaded(true);
        await getChat(document_id);
        getSummaryTitle(document_id);
        getFlashCardsTitle(document_id);
        await getFlashCards(document_id);
        await getSummary(document_id);
        setIsChangingDocument(false);
    };

    const clearChat = async () => {
        //Do not do anything if chat is already empty to avoid unnecessary API calls
        if (chat.length === 0) {
            return;
        }
        try {
            //Using document id here to delete
            await axios.get(config.api_urls.clear_chat, { params: { document_id } });
            setChat([]);
            console.log("Chat successfully cleared");
        } catch (error) {
            console.log(error);
        }

        capture_event(EVENTS.TUTOR_CHAT_CLEARED, {
            document_id: document_id,
        });
    };

    const getSummary = async (document_id) => {
        try {
            const response = await axios.get(config.api_urls.get_summary, {
                params: { document_id: document_id },
            });
            const summaryData = response.data;
            console.log("fetched summary:");
            console.log(summaryData);
            if (!summaryData || summaryData === "" || !summaryData.download) {
                sethasSummarized(false);
                setSummary("");
                setInitialSummary("");
                return;
            }

            if (summaryData.status === "complete") {
                setSummaryStatus("complete");
            }
            if (summaryData.status === "incomplete") {
                setSummaryStatus("incomplete");
            }
            if (summaryData.status === "exceeded limit") {
                setSummaryStatus("exceeded limit");
            }

            // Use the pre-signed URL to fetch the actual summary data from S3
            const s3Response = await axios.get(summaryData.download);
            const newSummary = s3Response.data; // Assuming the summary data is JSON
            //console.log("Summary",newSummary)
            sethasSummarized(true);
            setSummary(newSummary);
            setInitialSummary(newSummary);
            setSummaryId(summaryId + 1);
        } catch (error) {
            console.log(error);
        }
    };

    const getSummaryTitle = async (document_id) => {
        try {
            const response = await axios.get(config.api_urls.get_summary_title, {
                params: { document_id: document_id },
            });
            const newSummaryTitle = response.data;
            setSummaryTitle(newSummaryTitle);
        } catch (error) {
            console.log(error);
        }
    };

    const getFlashCards = async (document_id) => {
        let response;
        try {
            response = await axios.get(config.api_urls.get_flashcards, {
                params: { document_id: document_id },
                validateStatus: false,
            });
        } catch (error) {
            if (error.response.status !== 404) {
                console.log(error);
            }
            response = error.response;
        }

        if (response.status === 404) {
            setHasFlashCards(false);
            setFlashCards("");
            console.info("No flashcards found");
            return;
        }

        const downloadFlashcards = response.data;
        const s3Response = await axios.get(downloadFlashcards);
        const newFlashcards = s3Response.data;
        setFlashCardStatus(newFlashcards.status);
        setFlashCardLogs(newFlashcards.logs);
        console.log(newFlashcards);
        //console.log(newFlashcards.logs);
        setFlashCards(newFlashcards.flashcards);
        setHasFlashCards(true);
        //console.log("Flashcards Logs", flashcardLogs);
        console.log("Flashcards Status:", newFlashcards.status);
        console.log("Flashcards", newFlashcards.flashcards);
    };

    const getFlashCardsTitle = async (document_id) => {
        try {
            const response = await axios.get(config.api_urls.get_flashcards_title, {
                params: { document_id: document_id },
            });
            const newFlashCardsTitle = response.data;
            setFlashCardsTitle(newFlashCardsTitle);
        } catch (error) {
            console.log(error);
        }
    };

    const getChat = async (document_id) => {
        try {
            const response = await axios.get(config.api_urls.get_chat, {
                params: { document_id: document_id },
            });
            const newChat = response.data;
            console.log("chat loaded");
            console.log("New Chat", newChat);
            setChat(newChat);
        } catch (error) {
            console.error("error loading Chat from Backend");
            console.log(error);
        }
    };

    const handleWebSocketRequest = (document_id, actionType, webSocketUrl) => {
        return new Promise((resolve, reject) => {
            const ws = new WebSocket(webSocketUrl);
            const TIMEOUT_DURATION = 15 * 60 * 1000; // 15 minutes
            let timeoutId;

            ws.onopen = () => {
                console.log("Websocket connection opened");
                ws.send(
                    JSON.stringify({ action: actionType, message: document_id, user_id: user_id })
                );
                console.log(`Sent ${actionType} request`);
                timeoutId = setTimeout(() => {
                    ws.close();
                    reject(new Error("Timeout waiting for WebSocket response"));
                }, TIMEOUT_DURATION);
            };

            ws.onmessage = (event) => {
                clearTimeout(timeoutId);
                console.log(event);
                const data = JSON.parse(event.data);
                if ("percentage" in data) {
                    setLoadingUpload(data.message);
                    return;
                }
                if ("status_code" in data && data.status_code !== 200) {
                    setErrorInfo({
                        isVisible: true,
                        message: data.message,
                        statusCode: data.status_code,
                    });
                    resolve(null);
                    ws.close();
                }
                console.log(data);
                resolve(data);
                ws.close();
                console.log("Websocket connection closed");
            };

            ws.onerror = (error) => {
                clearTimeout(timeoutId);
                console.error("WebSocket error: ", error);
                reject(error);
            };

            ws.onclose = (event) => {
                clearTimeout(timeoutId);
                if (!event.wasClean) {
                    reject(new Error("WebSocket closed with unexpected error"));
                }
            };
        });
    };

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const generateSummary = async (
        document_id,
        language,
        summary_config,
        pages,
        include_images,
        onSummaryReceived,
        onError
    ) => {
        const ws = new WebSocket(config.base_wss_address);
        const TIMEOUT_DURATION = 15 * 60 * 1000; // 15 minutes
        const KEEP_ALIVE_INTERVAL = 10 * 1000; // 5 seconds
        let timeoutId;
        let keepAliveId;

        const sendKeepAlive = () => {
            if (ws.readyState === ws.OPEN) {
                console.log("Sending keep-alive ping");
                ws.send(JSON.stringify({ action: "keepAlive" }));
            }
        };

        ws.onopen = () => {
            console.log("Websocket connection opened");
            ws.send(
                JSON.stringify({
                    action: "summary_v5",
                    message: document_id,
                    user_id: user_id,
                    language: language,
                    config: summary_config,
                    pages: pages,
                    include_images: include_images,
                })
            );
            console.log("Send Summary request");
            timeoutId = setTimeout(() => {
                ws.close();
                onError(new Error("Timeout waiting for WebSocket response"));
            }, TIMEOUT_DURATION);
            keepAliveId = setInterval(sendKeepAlive, KEEP_ALIVE_INTERVAL);
        };

        ws.onmessage = async (event) => {
            clearTimeout(timeoutId);
            clearInterval(keepAliveId);
            const data = JSON.parse(event.data);

            // Check if it's a keep-alive response
            if (typeof data === "string") {
                console.log("Keep-alive response received");
                console.log(data);
                await sleep(10000);
                sendKeepAlive();
                return;
            }

            // Check if it is a progress update
            if ("percentage" in data) {
                setLoadingSummaryText(data.message);
                return;
            }

            if ("status_code" in data) {
                // The first condition is an error that is handled separately
                if (data.status_code === 201) {
                    setSummaryStatus("exceeded limit");
                    setisSummarizing(false);
                    ws.close();
                }

                if (data.status_code !== 200 && data.status_code !== 201) {
                    setErrorInfo({
                        isVisible: true,
                        message: data.message,
                        statusCode: data.status_code,
                    });
                    setisSummarizing(false);
                    ws.close();
                }
            }

            console.log(data);
            onSummaryReceived(data);
            setLoadingSummaryText("Preparing document for summary");
            ws.close();
            console.log("Websocket connection closed");
        };

        ws.onerror = (error) => {
            clearTimeout(timeoutId);
            clearInterval(keepAliveId);
            console.error("WebSocket error: ", error);
            onError(error);
        };

        ws.onclose = (event) => {
            clearTimeout(timeoutId);
            clearInterval(keepAliveId);
            if (!event.wasClean) {
                onError(new Error("WebSocket closed with unexpected error"));
            }
        };
    };

    const handleSummaryCreation = (document_id, language, config, pages, include_images) => {
        sethasSummarized(false);
        const randomIndex = Math.floor(Math.random() * jokes.length);
        if (randomIndex >= 0 || randomIndex < jokes.length) {
            setJoke(jokes[randomIndex]);
        }
        setisSummarizing(true);
        generateSummary(
            document_id,
            language,
            config,
            pages,
            include_images,
            async (reply) => {
                // onSummaryReceived callback
                console.log(reply);
                setisSummarizing(false);
                setIsChangingDocument(true);
                await getSummary(document_id);
                setIsChangingDocument(false);
            },
            (error) => {
                // onError callback
                console.log(error);
                setisSummarizing(false);
            }
        );

        capture_event(EVENTS.SUMMARY_GENERATED, {
            document_id: document_id,
            language: language,
            summary_config: config,
            pages: pages,
            include_images: include_images,
        });
    };

    const generateFlashcards = async (
        document_id,
        language,
        flashcard_config,
        pages,
        include_images,
        onFlashcardsReceived,
        onError
    ) => {
        const ws = new WebSocket(config.base_wss_address);
        const TIMEOUT_DURATION = 15 * 60 * 1000; // 15 minutes
        const KEEP_ALIVE_INTERVAL = 10 * 1000; // 5 seconds
        let timeoutId;
        let keepAliveId;
        let request_uuid = uuidv4();

        const sendKeepAlive = () => {
            if (ws.readyState === ws.OPEN) {
                console.log("Sending keep-alive ping");
                ws.send(JSON.stringify({ action: "keepAlive" }));
            }
        };

        ws.onopen = () => {
            console.log("Websocket connection opened");
            ws.send(
                JSON.stringify({
                    action: "generate_flashcards",
                    message: document_id,
                    user_id: user_id,
                    language: language,
                    config: flashcard_config,
                    pages: pages,
                    include_images: include_images,
                    request_uuid: request_uuid,
                })
            );
            console.log("Send Flashcards request");
            timeoutId = setTimeout(() => {
                ws.close();
                onError(new Error("Timeout waiting for WebSocket response"));
            }, TIMEOUT_DURATION);
            keepAliveId = setInterval(sendKeepAlive, KEEP_ALIVE_INTERVAL);
        };

        ws.onmessage = async (event) => {
            clearTimeout(timeoutId);
            clearInterval(keepAliveId);
            const data = JSON.parse(event.data);

            // Check if it's a keep-alive response
            if (typeof data === "string") {
                console.log("Keep-alive response received");
                console.log(data);
                await sleep(10000);
                sendKeepAlive();
                return;
            }

            // Check if it is a progress update
            if ("percentage" in data) {
                setLoadingFlashcardsText(data.message);
                return;
            }

            if ("status_code" in data) {
                // The first error is handled separately
                if (data.status_code === 201) {
                    setFlashCardStatus("exceeded limit");
                    setIsGeneratingFlashCards(false);
                    setErrorInfo({
                        isVisible: true,
                        message: data.message,
                        statusCode: data.status_code,
                    });
                    ws.close();
                }

                if (data.status_code === 200) {
                    let response_uuid = data.request_uuid ?? 0;
                    if (response_uuid === request_uuid) {
                        console.log("Request UUID match found");
                        console.log(data);
                        ws.close();
                        console.log("Websocket connection closed");
                    } else {
                        console.log("Request UUID mismatch");
                        console.log("keeping websocket open");
                        console.log(data);
                        return;
                    }
                }

                if (data.status_code !== 200 && data.status_code !== 201) {
                    setIsGeneratingFlashCards(false);
                    setErrorInfo({
                        isVisible: true,
                        message: data.message,
                        statusCode: data.status_code,
                    });
                    ws.close();
                }
            }

            console.log(data);
            onFlashcardsReceived(data);
            setLoadingFlashcardsText("Preparing document for generating flashcards");
            ws.close();
            console.log("Websocket connection closed");
        };

        ws.onerror = (error) => {
            clearTimeout(timeoutId);
            clearInterval(keepAliveId);
            console.error("WebSocket error: ", error);
            onError(error);
        };

        ws.onclose = (event) => {
            clearTimeout(timeoutId);
            clearInterval(keepAliveId);
            if (!event.wasClean) {
                onError(new Error("WebSocket closed with unexpected error"));
            }
        };
    };

    const handleFlashcardCreation = (document_id, language, config, pages, includeImages) => {
        const hadFlascardsBefore = hasFlashCards;
        setShowRegenerateCardsModal(false);
        setHasFlashCards(false);
        setIsGeneratingFlashCards(true);
        const randomIndex = Math.floor(Math.random() * jokes.length);
        if (randomIndex >= 0 || randomIndex < jokes.length) {
            setJoke(jokes[randomIndex]);
        }
        generateFlashcards(
            document_id,
            language,
            config,
            pages,
            includeImages,
            async (reply) => {
                console.log(reply);
                setIsGeneratingFlashCards(false);
                setIsChangingDocument(true);
                await getFlashCards(document_id);
                setIsChangingDocument(false);
            },
            (error) => {
                console.log(error);
                setIsGeneratingFlashCards(false);
            }
        );

        capture_event(
            hadFlascardsBefore ? EVENTS.FLASHCARDS_REGENERATED : EVENTS.FLASHCARDS_GENERATED,
            {
                document_id: document_id,
                language: language,
                flashcard_config: config,
                pages: pages,
                include_images: includeImages,
                card_count: flashcards.length,
            }
        );
    };

    const convertToPdf = async (document_id) => {
        try {
            const response = await handleWebSocketRequest(
                document_id,
                "convert_to_pdf",
                config.base_wss_address
            );
            console.log("Successfully converted file to pdf");
            return response;
        } catch (error) {
            console.log(error);
            setisUploading(false); // Handle error state
            return null;
        }
    };

    const extractText = async (document_id) => {
        try {
            const response = await handleWebSocketRequest(
                document_id,
                "extract_text_v5",
                config.base_wss_address
            );
            console.log("Successfully extracted text from pdf");
            return response;
        } catch (error) {
            console.log(error);
            setisUploading(false); // Handle error state
            return null;
        }
    };

    const download_summary_pdf = async () => {
        if (summary === "" || document_id === "" || isChangingDocument === true) {
            return;
        }

        const infoBannerTimeout = setTimeout(() => {
            showInfoBanner(
                "Please remain patient.\nLong Summaries with lots of images can take up to 3 minutes"
            );
        }, 15000); // 15 seconds

        const current_document_id = document_id;
        setIsGeneratingPDF(true);
        await trigger_save_summary();
        try {
            const response = await handleWebSocketRequest(
                current_document_id,
                "render_summary_pdf_v2",
                config.base_wss_address
            );
            const download_url = response.message;

            setDownloadLink(download_url);
            toggleDownloadModal();
            clearTimeout(infoBannerTimeout);
        } catch (error) {
            console.log(error);
            clearTimeout(infoBannerTimeout);
        }
        setIsGeneratingPDF(false);

        capture_event(EVENTS.SUMMARY_DOWNLOAD_LINK_GENERATED, {
            document_id: document_id,
        });
    };

    const download_flashcards = async () => {
        if (flashcards === "" || document_id === "" || isChangingDocument === true) {
            return;
        }

        capture_event(EVENTS.FLASHCARDS_DOWNLOAD_LINK_GENERATED, {
            document_id: document_id,
        });

        setIsExportingFlashCards(true);
        try {
            const response = await axios.get(config.api_urls.export_flashcards, {
                params: { document_id: document_id },
            });
            const download_url = response.data;

            setDownloadLink(download_url);
            toggleDownloadModal();
        } catch (error) {
            console.log(error);
        }
        setIsExportingFlashCards(false);
    };

    const processPdf = async (document_id) => {
        try {
            const response = await handleWebSocketRequest(
                document_id,
                "process_document_v5",
                config.base_wss_address
            );
            console.log("Successfully processed pdf");
            return response;
        } catch (error) {
            setErrorInfo({
                isVisible: true,
                message:
                    "There was an error uploading the file. Try again or contact our support if the issue persists.",
                statusCode: 203,
            });
            console.log(error);
            setisUploading(false); // Handle error state
            return null;
        }
    };

    const addInfoToDatabase = async (user_id, file, file_id, file_url) => {
        try {
            const response = await axios.get(config.api_urls.add_to_database, {
                params: {
                    user_id: user_id,
                    document_id: file_id,
                    file_name: file.name,
                    file_url: file_url,
                },
            });
            console.log("Document succesfully added to the database");
            return response;
        } catch (error) {
            console.error("There was an error adding the document to the database", error);
            setErrorInfo({
                isVisible: true,
                message:
                    "There was an error uploading the file. Try again or contact our support if the issue persists.",
                statusCode: 204,
            });
            return null;
        }
    };

    const addDocumentToUser = async (user_id, file_id, file_name, file_url) => {
        try {
            const response = await axios.get(config.api_urls.add_document_to_user, {
                params: {
                    user_id: user_id,
                    document_id: file_id,
                    file_name: file_name,
                    file_url: file_url,
                },
            });
            console.log("Document succesfully added to the database");
            return response;
        } catch (error) {
            console.error("There was an error adding the document to the user", error);
            setErrorInfo({
                isVisible: true,
                message:
                    "There was an error uploading the file. Try again or contact our support if the issue persists.",
                statusCode: 205,
            });
            return null;
        }
    };

    const uploadFileToS3 = async (presignedUrl, file) => {
        try {
            const config = {
                onUploadProgress: (progressEvent) => {
                    const percentage = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setUploadProgress(percentage);
                },
            };

            const result = await axios.put(presignedUrl, file, config);
            if (result.status === 200) {
                console.log("Successfully uploaded file to S3");
                return result;
            } else {
                console.error("Error uploading file to S3", result);
                setErrorInfo({
                    isVisible: true,
                    message:
                        "There was an error uploading the file. Try again or contact our support if the issue persists.",
                    statusCode: 206,
                });
                return null;
            }
        } catch (error) {
            console.error("There was an error uploading the file to S3", error);
            setErrorInfo({
                isVisible: true,
                message:
                    "There was an error uploading the file. Try again or contact our support if the issue persists.",
                statusCode: 206,
            });
            return null;
        }
    };

    const handleFileChange = async (e) => {
        setFileManagerVisible(false);
        const startTime = performance.now();
        const file = e.target.files[0];
        if (file) {
            trigger_save_summary();
            sethasUploaded(false);
            setSelectedUploadFile(file);
            setisUploading(true);
            console.log("File selected:", file);
            if (isPaidUser === false && file.size > MAX_UPLOAD_SIZE_FREE_IN_BYTES) {
                showError(
                    `Free users are allowed to upload files up to a size of ${
                        MAX_UPLOAD_SIZE_FREE_IN_BYTES / 1000000
                    }mb. Please upgrade to upload larger files.`,
                    207
                );
                setisUploading(false);
                return null;
            }
            if (isPaidUser === true && file.size > MAX_UPLOAD_SIZE_PAID_IN_BYTES) {
                showError(
                    `Users are allowed to upload files up to a size of ${
                        MAX_UPLOAD_SIZE_PAID_IN_BYTES / 1000000
                    }mb.`,
                    207
                );
                setisUploading(false);
                return null;
            }
            const AcceptedFileTypes = [
                ".pdf",
                ".docx",
                ".DOCX",
                ".doc",
                ".DOC",
                ".pptx",
                ".PPTX",
                ".ppt",
                ".PPT",
                ".odt",
                ".ODT",
            ];
            if (!AcceptedFileTypes.some((type) => file.name.endsWith(type))) {
                showError(
                    "Unsupported file type. You can upload the following file types: .pdf, .docx, .DOCX, .doc, .DOC, .pptx, .PPTX, .ppt, .PPT, .odt, .ODT",
                    208
                );
                setisUploading(false);
                return null;
            }
            // Call API to get presigned URL
            try {
                const response = await axios.get(config.api_urls.upload_url, {
                    params: {
                        user_id: user_id,
                        file_name: file.name,
                    },
                });
                const presignedUrl = response.data.presigned_url;
                const file_url = response.data.file_url;
                console.log(presignedUrl);
                const file_id = response.data.file_id;
                setDocumentId(file_id);
                console.log(file_id);
                const fileTypesToConvert = [
                    ".docx",
                    ".DOCX",
                    ".doc",
                    ".DOC",
                    ".pptx",
                    ".PPTX",
                    ".ppt",
                    ".PPT",
                    ".odt",
                    ".ODT",
                ];
                // Upload file to S3 using the presigned URL
                const responseS3 = await uploadFileToS3(presignedUrl, file);
                if (responseS3 === null) {
                    setisUploading(false);
                    return null;
                }
                setUploadProgress(0);
                setLoadingUpload("Extracting data...");
                const responseAddInfoToDb = await addInfoToDatabase(
                    user_id,
                    file,
                    file_id,
                    file_url
                );
                if (responseAddInfoToDb === null) {
                    setisUploading(false);
                    return null;
                }
                if (fileTypesToConvert.some((type) => file.name.endsWith(type))) {
                    setLoadingUpload("Converting file..");
                    const responseConvertToPdf = await convertToPdf(file_id);
                    if (responseConvertToPdf === null) {
                        setisUploading(false);
                        return null;
                    }
                }
                const reponseExtractText = await extractText(file_id);
                if (reponseExtractText === null) {
                    setisUploading(false);
                    return null;
                }
                const responseProcessPdf = await processPdf(file_id);
                if (responseProcessPdf === null) {
                    setisUploading(false);
                    return null;
                }
                const responseAddDocumentToUser = await addDocumentToUser(
                    user_id,
                    file_id,
                    file.name,
                    file_url
                );
                if (responseAddDocumentToUser === null) {
                    setisUploading(false);
                    return null;
                }
                const pdfURL = await get_pdf_url(file_id);
                setFileNameId(fileNameId + 1);
                sethasUploaded(true);
                setPDFURL(pdfURL);
                setSelectedUploadFile(null);
                setChat([{ text: welcomeMessage, uid: -1 }]);
                sethasSummarized(false);
                setSummary("");
                setSummaryTitle("");
                setHasFlashCards(false);
                setFlashCards("");
                setFlashCardsTitle("");
                setFlashCardLogs([]);
                setLoadingUpload("Uploading document");
                return file_id;
            } catch (error) {
                console.error(
                    "There was an error fetching the presigned URL or uploading the file",
                    error
                );
                setErrorInfo({
                    isVisible: true,
                    message:
                        "There was an error uploading the file. Try again or contact our support if the issue persists.",
                    statusCode: 206,
                });
                setSelectedUploadFile(null);
                return null;
            }
        }

        capture_event(EVENTS.DOCUMENT_UPLOADED, {
            document_id: document_id,
            file_name: file.name,
            duration: performance.now() - startTime,
        });
    };

    const handleUploadButtonClick = (directory_id = "home") => {
        wake_up_text_extract();
        wake_up_document_processor();
        wake_up_convert_to_pdf();
        document.getElementById(directory_id).click();
    };

    function addNode(parentId, newNode, fileSystem) {
        // This is a recursive function to search for the parent node and add the new node to its children
        function addNodeRecursive(nodes) {
            return nodes.map((node) => {
                if (node.id === parentId) {
                    if (node.children) {
                        return { ...node, children: [...node.children, newNode] };
                    }
                } else if (node.children) {
                    return { ...node, children: addNodeRecursive(node.children) };
                }
                return node;
            });
        }

        return addNodeRecursive(fileSystem);
    }

    const handleUploadDefault = async (e) => {
        wake_up_convert_to_pdf();
        wake_up_text_extract();
        wake_up_document_processor();
        const parentId = "subworkspace-1-1";
        const document_id = await handleFileChange(e);
        if (document_id === null) {
            console.error("There was an error uploading the document.");
            setisUploading(false);
            return;
        }
        const file = e.target.files[0];
        const date = new Date();
        const uploadedFile = {
            id: document_id,
            name: file.name,
            parentId: parentId,
            date: convertDateToString(date),
            type: "file",
            open: false,
            children: [],
        };
        setFileSytem(addNode(parentId, uploadedFile, fileSystem));
        await trigger_save_filesystem();
        setisUploading(false);

        capture_event(EVENTS.DOCUMENT_UPLOADED, { document_id: document_id, file_name: file.name });
    };

    const { openModal, hideModal } = useMemberstackModal();

    const OpenProfileModal = async () => {
        capture_event(EVENTS.USER_PROFILE_OPENED);
        openModal({ type: "PROFILE", planId: "pln_xxxxxxx" })
            .then(({ data, type }) => {
                if (type === "LOGOUT") {
                    handleLogout();
                    console.log("Member logged out");
                }

                hideModal();
            })
            .catch((error) => {
                console.error("Error in Memberstack modal:", error);
            });
    };

    const handleLogout = () => {
        setIsAuthenticated(false);
        setUserId(null);
        capture_event(EVENTS.USER_LOGGED_OUT);
    };

    const get_country_from_ip = async () => {
        if (countryCode !== "") {
            return;
        }

        let newCountryCode = "DE";
        try {
            const response = await axios.get(config.api_urls.get_county_from_ip, {
                params: { user_id: user_id },
            });
            const data = response.data;
            newCountryCode = data.countryCode;
            console.log("countryCode:", newCountryCode);
            if (countryCode === "Unknown") {
                console.info("Country code could not be determined");
                newCountryCode = "DE";
            }
        } catch (error) {
            console.error("Error in get_country_from_ip", error);
            newCountryCode = "DE";
        }
        setCountryCode(newCountryCode);
    };

    const handleAppModeChange = (mode) => {
        const prevMode = appMode;
        setAppMode(mode);
        capture_event(EVENTS.APP_MODE_CHANGED, { mode: mode, prevMode: prevMode });
        capture_event("app-mode-" + mode + "-selected");
    };

    const handleLanguageChange = async (language) => {
        setSelectedLanguage(language);
        capture_event(EVENTS.LANGUAGE_CHANGED, { language: language });
        try {
            //Using document id here to delete
            console.log("changing user's default language to " + language);
            const response = await axios.get(config.api_urls.set_default_language, {
                params: { user_id, language },
            });
            console.log(response.data);
            console.log("Language successfully updated");
        } catch (error) {
            console.log(error);
        }
    };

    const submitFeedback = async (text, stars) => {
        try {
            const response = await axios.get(config.api_urls.upload_feedback_as_json, {
                params: {
                    stars: stars,
                },
            });
            const presignedUrl = response.data.presigned_url;
            const feedback_id = response.data.feedback_id;
            //console.log(presignedUrl)
            //console.log("feedback_id:", feedback_id);

            let browser = "unknown browser";
            let os = "unknown os";
            let device = { vendor: "unknown vendor", model: "unknown model", type: "unknown type" };

            try {
                const userDeviceDetails = getUserDeviceDetails();
                browser = userDeviceDetails.browser;
                os = userDeviceDetails.os;
                device = userDeviceDetails.device;
            } catch {
                console.log("User device details could not be determined");
            }

            const feedback = {
                text: text,
                stars: stars,
                user_id: user_id,
                document_id: document_id,
                feedback_mode: feedbackMode,
                browser: browser,
                os: os,
                device: device,
            };

            const feedbackJson = JSON.stringify(feedback);
            const file = new Blob([feedbackJson], { type: "application/json" });

            //console.log(feedbackJson)

            await uploadFileToS3(presignedUrl, file);
            await axios.get(config.api_urls.submit_feedback, { params: { feedback_id, stars } });
            //console.log(submitted_feedback.data);
        } catch (error) {
            console.log(error);
        } finally {
            capture_event(EVENTS.FEEDBACK_SUBMITTED, { stars: stars, feedback_mode: feedbackMode });
        }
    };

    return (
        <div className={styles["app-container"]}>
            <Toolbar
                currentAppMode={appMode}
                onAppModeChange={handleAppModeChange}
                onToggleFileManager={toggleFileManager}
                onFeedbackButtonClick={toggleFeedbackModal}
                onUpgrade={toggleUpgradeModal}
                onProfileClick={OpenProfileModal}
                onClearChat={clearChat}
                onRegenerateFlashcards={handleRegenerateFlashcards}
                isPaidUser={isPaidUser}
                currentLanguage={selectedLanguage}
                onLanguageChange={handleLanguageChange}
                numberOfRepetitions={numberOfRepetitions}
                setNumberOfRepetitions={setNumberOfRepetitions}
                isExportingFlashCards={isExportingFlashCards}
                download_flashcards={download_flashcards}
                toggleResetModal={toggleResetModal}
                download_summary_pdf={download_summary_pdf}
                onSaveSummary={trigger_save_summary}
                isGeneratingPDF={isGeneratingPDF}
                isSavingSummary={isSavingSummary}
                isStudyModeActive={isStudyModeActive}
                toggleStudyMode={toggleStudyMode}
                isShowingCheckIcon={isShowingCheckIcon}
            />

            <OverlayModal
                height="620px"
                isVisible={showRegenerateCardsModal}
                onClose={() => setShowRegenerateCardsModal(false)}>
                <p className={styles.regenerateFlashcardsText}>Regenerate Flashcards</p>
                <p className={styles.regenerateFlashcardsText}>
                    Be aware that this will permanently delete all existing cards in the current
                    deck.
                </p>
                <GenerateFlashcardsModal
                    document_id={document_id}
                    handleFlashcardCreation={handleFlashcardCreation}
                    appLanguage={selectedLanguage}
                    showInfoBanner={showInfoBanner}
                />
            </OverlayModal>

            {bannerData.visible && (
                <InfoBanner
                    message={bannerData.message}
                    visible={bannerData.visible}
                    color={bannerData.color}
                    setBannerData={setBannerData}></InfoBanner>
            )}
            {errorInfo.statusCode === 201 ? (
                <UpgradeModal
                    isVisible={errorInfo.isVisible}
                    onClose={hideError}
                    onClickUpgradeNow={toggleUpgradeModal}
                />
            ) : (
                <ErrorModal
                    isVisible={errorInfo.isVisible}
                    message={errorInfo.message}
                    onClose={hideError}
                    statusCode={errorInfo.statusCode}
                    submitSupportRequest={submitSupportRequest}></ErrorModal>
            )}

            {isFileManagerVisible && (
                <FileManagerNew
                    fileSystem={fileSystem}
                    setFileSytem={setFileSytem}
                    isVisible={isFileManagerVisible}
                    onClose={() => toggleFileManager()}
                    handleUploadButtonClick={handleUploadButtonClick}
                    onFileSelect={handleFileSelect}
                    handleFileChange={handleFileChange}
                    handleUploadDefault={handleUploadDefault}
                    trigger_save_filesystem={trigger_save_filesystem}
                    saveFilesystem={saveFileSystem}
                    setIsUploading={setisUploading}
                    topComparator={topComparator}
                    setTopComparator={setTopComparator}
                />
            )}

            {isFeedbackModalVisible && (
                <FeedbackModal
                    isVisible={isFeedbackModalVisible}
                    onClose={() => toggleFeedbackModal()}
                    submitFeedback={submitFeedback}
                />
            )}

            <PricingModal
                isUpgradeModalOpen={isUpgradeModalOpen}
                toggleUpgradeModal={toggleUpgradeModal}
                countryCode={countryCode}
            />

            {isDownloadModalVisible && (
                <DownloadModal
                    isVisible={isDownloadModalVisible}
                    onClose={toggleDownloadModal}
                    downloadLink={downloadLink}
                />
            )}

            {hasUploaded === false && isUploading === false && (
                <div className={styles["uploadContainer"]}>
                    <StartLayer
                        handleUploadButtonClick={handleUploadButtonClick}
                        selectedUploadFile={selectedUploadFile}
                        handleUploadDefault={handleUploadDefault}
                        onToggleFileManager={toggleFileManager}
                    />
                </div>
            )}

            {hasUploaded === false && isUploading === true && (
                <div className={styles["loadingAnimationUpload"]}>
                    <ClipLoader color="#256EFF" />
                    {uploadProgess > 0 && uploadProgess < 100 ? (
                        <p>Uploading document</p>
                    ) : (
                        <div className={styles["loadingAnimationUploadParagraphContainer"]}>
                            {loadingUpload.split("\n").map((paragraph, index) => (
                                <p className={styles["paragraphUpload"]} key={index}>
                                    {paragraph}
                                </p>
                            ))}
                        </div>
                    )}
                    {uploadProgess > 0 && uploadProgess < 100 && (
                        <progress
                            className={styles["progressbar"]}
                            value={uploadProgess}
                            max="100"
                        />
                    )}
                </div>
            )}

            {hasUploaded === true && !(appMode === APP_MODE_CARDS && isStudyModeActive) && (
                <div className={styles["content-container"]}>
                    {pdfViewerVisible && (
                        <div className={styles["pdf-container"]}>
                            <ReactPDFViewer
                                pdfUrl={pdfURL}
                                currentAreas={currentAreas}
                                currentPageReference={currentPageReference}
                            />
                        </div>
                    )}
                    {appMode === APP_MODE_TUTOR && renderRightSideMode() && (
                        <div className={styles["chat-container"]}>
                            <ChatRoom
                                user_id={user_id}
                                document_id={document_id}
                                chat={chat}
                                setChat={setChat}
                                selectedLanguage={selectedLanguage}
                                toggleUpgradeModal={toggleUpgradeModal}
                                setCurrentAreas={setCurrentAreas}
                                setCurrentPageReference={setCurrentPageReference}
                                showInfoBanner={showInfoBanner}
                                onChangeAppMode={handleAppModeChange}
                            />
                        </div>
                    )}

                    {appMode === APP_MODE_SUMMARY &&
                        isChangingDocument &&
                        renderRightSideMode() && (
                            <div className={styles["loadingAnimationSummary"]}>
                                <ClipLoader color="#256EFF" />
                                <div className={styles["text"]}>
                                    <p>Loading Summary</p>
                                </div>
                            </div>
                        )}

                    {appMode === APP_MODE_SUMMARY &&
                        !hasSummarized &&
                        !isSummarizing &&
                        !isChangingDocument &&
                        !(summaryStatus === "exceeded limit") &&
                        renderRightSideMode() && (
                            <div className={styles["summarize-button-container"]}>
                                <GenerateSummaryModal
                                    document_id={document_id}
                                    handleSummaryCreation={handleSummaryCreation}
                                    appLanguage={selectedLanguage}
                                    showInfoBanner={showInfoBanner}
                                />
                            </div>
                        )}

                    {appMode === APP_MODE_SUMMARY &&
                        !hasSummarized &&
                        isSummarizing &&
                        !isChangingDocument &&
                        renderRightSideMode() && (
                            <div className={styles["loadingAnimationSummary"]}>
                                <BarLoader color="#256EFF" />
                                <div className={styles["text"]}>
                                    <p>
                                        <strong>{loadingSummaryText}</strong>
                                    </p>
                                </div>
                                <br></br>
                                <br></br>
                                <div className={styles["joke"]}>
                                    <p>
                                        <i>Random joke:</i>
                                    </p>
                                    <p>{joke.setup}</p>
                                    <p>{joke.punchline}</p>
                                </div>
                            </div>
                        )}

                    {appMode === APP_MODE_SUMMARY &&
                        hasSummarized &&
                        !isChangingDocument &&
                        renderRightSideMode() && (
                            <div className={styles["simple-text-editor-container"]}>
                                <TinyEditor
                                    key={summaryId}
                                    document_id={document_id}
                                    summary={summary}
                                    onSummaryChange={setSummary}
                                    summary_title={summary_title}
                                    setSummaryTitle={setSummaryTitle}
                                    toggleUpgradeModal={toggleUpgradeModal}
                                    triggerSaveSummary={trigger_save_summary}
                                    summaryStatus={summaryStatus}
                                    isPaidUser={isPaidUser}
                                    handleSummaryCreation={handleSummaryCreation}
                                    setCurrentAreas={setCurrentAreas}
                                    setCurrentPageReference={setCurrentPageReference}
                                    toggleFeedbackModal={toggleFeedbackModal}
                                    setFeedbackMode={setFeedbackMode}
                                />
                            </div>
                        )}

                    {appMode === APP_MODE_CARDS && isChangingDocument && renderRightSideMode() && (
                        <div className={styles["loadingAnimationSummary"]}>
                            <ClipLoader color="#256EFF" />
                            <div className={styles["text"]}>
                                <p>Loading Flashcards</p>
                            </div>
                        </div>
                    )}

                    {appMode === APP_MODE_CARDS &&
                        !hasFlashCards &&
                        isGeneratingFlashCards &&
                        !isChangingDocument &&
                        renderRightSideMode() && (
                            <div className={styles["loadingAnimationSummary"]}>
                                <BarLoader color="#256EFF" />
                                <div className={styles["text"]}>
                                    <p>
                                        <strong>{loadingFlashcardsText}</strong>
                                    </p>
                                </div>
                                <br></br>
                                <br></br>
                                <div className={styles["joke"]}>
                                    <p>
                                        <i>Random joke:</i>
                                    </p>
                                    <p>{joke.setup}</p>
                                    <p>{joke.punchline}</p>
                                </div>
                            </div>
                        )}

                    {appMode === APP_MODE_CARDS &&
                        !hasFlashCards &&
                        !isGeneratingFlashCards &&
                        !isChangingDocument &&
                        renderRightSideMode() && (
                            <div className={styles["flashcard-button-container"]}>
                                <GenerateFlashcardsModal
                                    document_id={document_id}
                                    handleFlashcardCreation={handleFlashcardCreation}
                                    appLanguage={selectedLanguage}
                                    showInfoBanner={showInfoBanner}
                                />
                            </div>
                        )}

                    {appMode === APP_MODE_CARDS &&
                        hasFlashCards &&
                        !isChangingDocument &&
                        flashcardStatus !== "exceeded limit" &&
                        renderRightSideMode() && (
                            <div className={styles["flashcard-container"]}>
                                <FlashcardContainer
                                    isStudyModeActive={isStudyModeActive}
                                    document_id={document_id}
                                    user_id={user_id}
                                    uploadFileToS3={uploadFileToS3}
                                    flashcards_title={flashcards_title}
                                    setFlashCardsTitle={setFlashCardsTitle}
                                    flashcards={flashcards}
                                    getFlashcardsRefMap={getFlashcardsRefMap}
                                    setFlashCards={setFlashCards}
                                    flashcardStatus={flashcardStatus}
                                    flashcardLogs={flashcardLogs}
                                    isPaidUser={isPaidUser}
                                    toggleUpgradeModal={toggleUpgradeModal}
                                    handleFlashcardCreation={
                                        handleFlashcardCreation
                                    }></FlashcardContainer>
                            </div>
                        )}
                </div>
            )}

            {hasUploaded === true &&
                appMode === APP_MODE_CARDS &&
                isStudyModeActive &&
                !isChangingDocument &&
                renderRightSideMode() && (
                    <FlashcardContainer
                        isStudyModeActive={isStudyModeActive}
                        numberOfRepetitions={numberOfRepetitions}
                        flashcardLogs={flashcardLogs}
                        setFlashCardLogs={setFlashCardLogs}
                        isResetModalOpen={isResetModalOpen}
                        toggleResetModal={toggleResetModal}
                        document_id={document_id}
                        user_id={user_id}
                        uploadFileToS3={uploadFileToS3}
                        flashcards_title={flashcards_title}
                        setFlashCardsTitle={setFlashCardsTitle}
                        flashcards={flashcards}
                        setFlashCards={setFlashCards}
                        flashcardStatus={flashcardStatus}
                        isPaidUser={isPaidUser}
                        toggleUpgradeModal={toggleUpgradeModal}
                        handleFlashcardCreation={handleFlashcardCreation}></FlashcardContainer>
                )}
            {hasUploaded === true && !(appMode === APP_MODE_CARDS && isStudyModeActive) && (
                <div className={styles["bottom-text"]}>
                    <div className={styles["left-half"]}></div>
                    <div className={styles["right-half"]}>
                        The Learnboost AI may produce inaccurate information about people, places,
                        or facts.
                    </div>
                </div>
            )}

            <ToolbarBottom
                isPdfViewerVisible={pdfViewerVisible}
                onTogglePdfViewer={togglePdfViewer}
                onClickFileManager={toggleFileManager}
                currentAppMode={appMode}
                onAppModeChange={handleAppModeChange}
            />
        </div>
    );
}

// this is the "main" entrypoint
function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user_id, setUserId] = useState(null);
    const [isPaidUser, setIsPaidUser] = useState(false);
    const [plan, setPlan] = useState("FREE");
    const [language, setLanguage] = useState("German");

    const handleAuthSuccess = () => {
        setIsAuthenticated(true);
    };

    // this is the "mobile-optimization" to hard-set the view-sizes
    // for mobile-phones and tables
    // executed with a timeout because it's somehow not working otherwise
    //setTimeout(updateViewportMetaTag, 1000);

    return (
        <MemberstackProvider config={config.memberstack}>
            {isAuthenticated ? (
                <Learnboost
                    user_id={user_id}
                    setUserId={setUserId}
                    setIsAuthenticated={setIsAuthenticated}
                    isPaidUser={isPaidUser}
                    plan={plan}
                    language={language}
                />
            ) : (
                <AuthPage
                    onAuthSuccess={handleAuthSuccess}
                    setUserId={setUserId}
                    setIsPaidUser={setIsPaidUser}
                    setPlan={setPlan}
                    language={language}
                    setLanguage={setLanguage}
                />
            )}
        </MemberstackProvider>
    );
}

export default App;
