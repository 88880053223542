const RepetitionIcon = () => {
    return (
        <svg
            width="20"
            height="22"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.5 1L19 4.5M19 4.5L15.5 8M19 4.5H4C2.89543 4.5 2 5.39543 2 6.5V8.5"
                stroke="#222222"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.5 13.5L1 17M1 17L4.5 20.5M1 17L16 17C17.1046 17 18 16.1046 18 15L18 13"
                stroke="#222222"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default RepetitionIcon;
