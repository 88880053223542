import React, { useEffect, useRef, useState } from "react";
import FlashcardMultipleChoice from "./FlashcardMultipleChoice";
import FlashcardQaPairHtml from "./FlashcardQaPairHtml";

const Types = {
    FlashcardQaPairHtml: FlashcardQaPairHtml,
    FlashcardMultipleChoice: FlashcardMultipleChoice,
};

const Flashcard = ({
    card,
    isStudyModeActive,
    onCardChange,
    savingCardIds,
    finishedSavingCardIds,
    cardNumber,
    numberOfCards,
    numberOfRepetitions,
    getFlashcardsRefMap,
}) => {
    if (typeof Types[card.type] === "undefined") {
        console.log("Unknown card type: " + card.type);
        card.type = "FlashcardQaPairHtml";
    }

    const useLazyLoad = (ref, options = {}) => {
        const [isVisible, setIsVisible] = useState(
            // if question is empty the card was just created by the user, we need to render it immediately
            isStudyModeActive || card.question === "" ? true : false
        );
        const reference = ref.current;
        useEffect(() => {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        try {
                            observer.unobserve(reference);
                        } catch {}
                    }
                });
            }, options);

            if (reference && !isVisible) {
                observer.observe(reference);
            }

            return () => {
                if (reference) {
                    observer.disconnect();
                }
            };
        }, [reference, options, isVisible]);

        return isVisible;
    };

    const ref = useRef();
    const isVisible = useLazyLoad(ref);

    if (typeof Types[card.type] === "undefined") {
        console.log("Unknown card type: " + card.type);
        return <div ref={ref}>Unknown card type: {card.type}</div>;
    }

    return (
        <div ref={ref} id={card.id}>
            {isVisible &&
                React.createElement(Types[card.type], {
                    key: card.id,
                    card: card,
                    isStudyModeActive: isStudyModeActive,
                    onCardChange: onCardChange,
                    savingCardIds: savingCardIds,
                    finishedSavingCardIds: finishedSavingCardIds,
                    cardNumber: cardNumber,
                    numberOfCards: numberOfCards,
                    numberOfRepetitions: numberOfRepetitions,
                    getFlashcardsRefMap: getFlashcardsRefMap,
                })}
        </div>
    );
};

export default Flashcard;
