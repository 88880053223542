const AddFolderIcon = ({ width = 24, height = 24 }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none">
            <path
                d="M2 8C2 6.11438 2 5.17157 2.58579 4.58579C3.17157 4 4.11438 4 6 4H7.94705C8.71444 4 9.09814 4 9.44736 4.13558C9.79658 4.27116 10.0798 4.53007 10.6461 5.04788L12.1039 6.38069C12.6702 6.8985 12.9534 7.15741 13.3026 7.29299C13.6519 7.42857 14.0356 7.42857 14.803 7.42857H18C19.8856 7.42857 20.8284 7.42857 21.4142 8.01436C22 8.60014 22 9.54295 22 11.4286V16C22 17.8856 22 18.8284 21.4142 19.4142C20.8284 20 19.8856 20 18 20H6C4.11438 20 3.17157 20 2.58579 19.4142C2 18.8284 2 17.8856 2 16V8Z"
                stroke="#33363F"
                strokeWidth="1.5"
            />
            <path d="M11.6001 11V15.8" stroke="#33363F" strokeWidth="1.5" strokeLinecap="round" />
            <path
                d="M14 13.4004L9.2 13.4004"
                stroke="#33363F"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};
export default AddFolderIcon;
