import cloneDeep from "lodash/cloneDeep";
import React, { useMemo, useRef, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import FlashCardButton from "../FlashcardsMode/FlashcardButton";
import Icon from "../Icon";
import styles from "./Flashcard.module.css";
import studystyles from "./FlashcardStudyMode.module.css";

const EditCard = ({
    cardPosition,
    card,
    onQuestionChange,
    onAnswerChange,
    onAnswerIsCorrectChange,
    onSaveCard,
    onDeleteCard,
    savingCardIds,
    finishedSavingCardIds,
    getFlashcardsRefMap,
}) => {
    return (
        <div
            ref={(node) => {
                const map = getFlashcardsRefMap();
                if (node) {
                    map.set(card.id, node);
                } else {
                    map.delete(card.id);
                }
            }}
            className={styles.container}>
            <div className={styles.cardColoring}></div>
            <div className={styles.content}>
                <div className={styles.cardHeadline}>
                    <textarea
                        className={styles.headlineInput}
                        placeholder={"Title of your card"}
                        defaultValue={card.question}
                        onBlur={(e) => {
                            onQuestionChange(e.target.value);
                        }}></textarea>
                    <div className={styles.number}>{cardPosition}</div>
                </div>
                <div className={[styles.cardContent]} tabIndex="0">
                    <div className={styles.multipleChoiceAnswers}>
                        {card.answers.map(({ answer, is_correct }, index) => (
                            <div key={index} className={styles.answer}>
                                <input
                                    className={styles.answerCheckbox}
                                    id={`answer-${index}`}
                                    type="checkbox"
                                    defaultChecked={is_correct}
                                    onChange={(e) => {
                                        onAnswerIsCorrectChange(index, e.target.checked);
                                    }}
                                />
                                <textarea
                                    className={styles.answerInput}
                                    contentEditable="true"
                                    suppressContentEditableWarning={true}
                                    defaultValue={answer}
                                    onInput={(e) => {
                                        e.target.style.height = "auto";
                                        e.target.style.height = e.target.scrollHeight - 15 + "px";
                                    }}
                                    onBlur={(e) => {
                                        onAnswerChange(index, e.target.value);
                                    }}></textarea>
                            </div>
                        ))}
                    </div>
                    <div className={styles.controlIcons}>
                        {savingCardIds.includes(card.id) ? (
                            <span className={styles.icon}>
                                <ClipLoader className={styles.cliploader} />
                            </span>
                        ) : finishedSavingCardIds.includes(card.id) ? (
                            <span className={styles.icon}>
                                <Icon name="CheckIcon" />
                            </span>
                        ) : (
                            <span className={styles.icon} onClick={onSaveCard}>
                                <Icon name="DiskIcon" />
                            </span>
                        )}
                        <span className={styles.icon} onClick={onDeleteCard}>
                            <Icon name="TrashIcon" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

const StudyCard = ({ card, cardPosition, numberOfRepetitions, onCardChange }) => {
    const question = card.question || "Title of your Card";

    const shuffledAnswers = useMemo(() => {
        console.log("Shuffling answers " + card.id);
        let shuffledAnswers = cloneDeep(card.answers);
        shuffledAnswers.sort(() => Math.random() - 0.5);
        return shuffledAnswers;
    }, [card]);

    const [showAnswer, setShowAnswer] = useState(false);
    const checkBoxRef = useRef(null);

    const wasCardAnsweredCorrectly = () => {
        return shuffledAnswers.every((answer, index) => {
            return answer.is_correct === checkBoxRef[index].checked;
        });
    };

    const getAnswerColor = (position, showAnswer) => {
        if (!showAnswer) {
            return "";
        }

        if (shuffledAnswers[position].is_correct) {
            return studystyles.correctAnswer;
        }

        if (shuffledAnswers[position].is_correct !== checkBoxRef[position].checked) {
            return studystyles.wrongAnswer;
        }

        return "";
    };

    const formatTextForDisplay = (text) => {
        return text.replace(/\n/g, "<br />");
    };

    const toggleFullView = () => {
        setShowAnswer(!showAnswer);
    };

    return (
        <>
            <div className={studystyles.container}>
                <div className={studystyles.content}>
                    <div className={styles.cardHeadline}>
                        <div
                            className={studystyles.headline}
                            dangerouslySetInnerHTML={{
                                __html: formatTextForDisplay(question),
                            }}></div>
                        <div className={styles.number}>{cardPosition}</div>
                    </div>
                    <div className={styles.cardContent}>
                        <div className={styles.multipleChoiceAnswers}>
                            {shuffledAnswers.map(({ answer, is_correct }, index) => (
                                <div
                                    onClick={() => checkBoxRef[index].click()}
                                    key={index}
                                    data-is-correct={is_correct}
                                    className={`${studystyles.answer} ${getAnswerColor(
                                        index,
                                        showAnswer
                                    )}`}>
                                    <span className={styles.answerIsCorrect}>
                                        <input
                                            ref={(ref) => (checkBoxRef[index] = ref)}
                                            className={studystyles.answerCheckbox}
                                            disabled={showAnswer}
                                            id={`answer-${index}`}
                                            type="checkbox"
                                            onClick={() => checkBoxRef[index].click()}
                                        />
                                    </span>
                                    <label
                                        htmlFor={`answer-${index}`}
                                        className={studystyles.answerInput}
                                        readOnly={true}
                                        onClick={() => checkBoxRef[index].click()}>
                                        {answer}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <div className={styles.controlIcons}>
                            {card.count_correct_answers}/{numberOfRepetitions}
                        </div>
                    </div>
                </div>
                <div className={studystyles.cardColoring}></div>
            </div>
            <div className={studystyles.menuContainer}>
                {!showAnswer && (
                    <FlashCardButton type={"uncover"} onClick={toggleFullView}></FlashCardButton>
                )}
                {showAnswer && (
                    <>
                        <FlashCardButton
                            type={wasCardAnsweredCorrectly() ? "next-correct" : "next-incorrect"}
                            onClick={() => {
                                onCardChange(
                                    wasCardAnsweredCorrectly()
                                        ? "increaseCorrect"
                                        : "increaseIncorrect",
                                    card
                                );
                                toggleFullView();
                            }}></FlashCardButton>
                    </>
                )}
            </div>
        </>
    );
};

const FlashcardMultipleChoice = ({
    card,
    isStudyModeActive,
    onCardChange,
    savingCardIds,
    finishedSavingCardIds,
    cardNumber,
    numberOfCards,
    numberOfRepetitions,
    showAnswer,
    getFlashcardsRefMap,
}) => {
    const cardPosition = `${cardNumber}/${numberOfCards}`;

    const handleQuestionChange = (question) => {
        const newCard = cloneDeep(card);

        const formattedQuestion = question
            .replace(/<div>/g, "\n")
            .replace(/<\/div>/g, "")
            .replace(/<br>/g, "\n")
            .replace(/&nbsp;/g, " ");

        newCard.question = formattedQuestion;
        onCardChange("saveCard", newCard);
    };

    const handleAnswerChange = (index, answer) => {
        const newCard = cloneDeep(card);
        newCard.answers[index].answer = answer;
        onCardChange("saveCard", newCard);
    };

    const handleAnswerIsCorrectChange = (index, is_correct) => {
        const newCard = cloneDeep(card);
        newCard.answers[index].is_correct = is_correct;
        onCardChange("saveCard", newCard);
    };

    const saveCardHandler = () => {
        console.log("saveCard triggered");
        onCardChange("saveCard", card);
    };

    const deleteCardHandler = () => {
        console.log("deleteCard triggered");
        onCardChange("deleteCard", card);
    };

    const countCardWasAnswered = card.count_correct_answers + card.count_incorrect_answers;

    return (
        <>
            {!isStudyModeActive ? (
                <EditCard
                    card={card}
                    cardPosition={cardPosition}
                    onQuestionChange={handleQuestionChange}
                    onAnswerChange={handleAnswerChange}
                    onAnswerIsCorrectChange={handleAnswerIsCorrectChange}
                    onSaveCard={saveCardHandler}
                    onDeleteCard={deleteCardHandler}
                    savingCardIds={savingCardIds}
                    finishedSavingCardIds={finishedSavingCardIds}
                    getFlashcardsRefMap={getFlashcardsRefMap}
                />
            ) : (
                <StudyCard
                    key={`${card.id}-${countCardWasAnswered}`}
                    card={card}
                    cardPosition={cardPosition}
                    numberOfRepetitions={numberOfRepetitions}
                    onCardChange={onCardChange}
                />
            )}
        </>
    );
};

export default FlashcardMultipleChoice;
