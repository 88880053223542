const checkAllFlashcardsCanStudied = (flashcards) => {
    return flashcards.every((flashcard) => {
        if (flashcard.type === "FlashcardMultipleChoice") {
            return checkFlashcardMultipleChoice(flashcard);
        }

        if (flashcard.type === "FlashcardQaPairHtml") {
            return checkFlashcardQAPairs(flashcard);
        }

        return true;
    });
};

const getFirstFlashcardNotReady = (flashcards) => {
    const errorCard = flashcards.find((flashcard) => {
        if (flashcard.type === "FlashcardMultipleChoice") {
            return !checkFlashcardMultipleChoice(flashcard);
        }

        if (flashcard.type === "FlashcardQaPairHtml") {
            return !checkFlashcardQAPairs(flashcard);
        }

        return false;
    });

    return errorCard.id;
};

const checkFlashcardMultipleChoice = (flashcard) => {
    if (flashcard.question === "") {
        return false;
    }

    let hasAtLeastOneCorrectAnswer = false;
    for (let i = 0; i < flashcard.answers.length; i++) {
        if (flashcard.answers[i].answer === "") {
            return false;
        }

        if (flashcard.answers[i].is_correct) {
            hasAtLeastOneCorrectAnswer = true;
        }
    }

    if (!hasAtLeastOneCorrectAnswer) {
        return false;
    }

    return true;
};

const checkFlashcardQAPairs = (flashcard) => {
    if (flashcard.question === "") {
        return flashcard.id;
    }

    if (flashcard.answer === "") {
        return false;
    }

    return true;
};

export { checkAllFlashcardsCanStudied, getFirstFlashcardNotReady };
