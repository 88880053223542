import React, { useState } from "react";
import Icon from "../Icon";
import styles from "./LanguageButton.module.css";

function LanguageButton({ languages, onLanguageChange, selectedLanguage }) {
    let DisplayLanguage = languages.filter((eachLang) => eachLang.key === selectedLanguage)[0];

    const [isLanguageDropdownOpen, setLanguageDropdownOpen] = useState(false);

    const onToggle = () => {
        setLanguageDropdownOpen(!isLanguageDropdownOpen);
    };
    const handleLangChange = (languageKey) => {
        onLanguageChange(languageKey);
        onToggle();
    };

    return (
        <div className={styles.dropdown}>
            <button className={styles.button} onClick={onToggle}>
                <span className={styles.title}>{DisplayLanguage.label}</span>
                <Icon name="ChevronDownIcon" />
            </button>
            {isLanguageDropdownOpen && (
                <div className={styles.dropdownContent}>
                    {languages.map((lang, index) => (
                        <button
                            key={index}
                            className={selectedLanguage === lang.key ? styles.selected : ""}
                            onClick={() => handleLangChange(lang.key)}>
                            {lang.label}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
}

export default LanguageButton;
