import { Editor } from "@tinymce/tinymce-react";
import React, { useRef } from "react";
import styles from "./FlashcardEditor.module.css";

const FlashcardEditor = ({ text, onTextChange, isReadOnly }) => {
    const editorRef = useRef(null);

    const base64_img_handler = (blobInfo) =>
        new Promise((resolve) => {
            resolve("data:image/png;base64," + blobInfo.base64());
        });

    return (
        <div className={styles.tinyEditor}>
            <Editor
                onInit={(evt, editor) => (editorRef.current = editor)}
                licenseKey="gpl"
                initialValue={text}
                init={{
                    height: "100%",
                    plugins: ["quickbars", "lists", "image", "table"],
                    toolbar: false,
                    menubar: false,
                    inline: true,
                    quickbars_selection_toolbar:
                        "bold italic underline numlist bullist quickimage quicktable alignleft aligncenter alignright",
                    quickbars_insert_toolbar: false,
                    autosave_ask_before_unload: true,
                    editable_root: !isReadOnly,
                    images_upload_handler: base64_img_handler,
                }}
                onFocusOut={(e) => {
                    if (editorRef.current.getContent() !== text) {
                        onTextChange && onTextChange(editorRef.current.getContent());
                    }
                }}
            />
        </div>
    );
};

export default FlashcardEditor;
