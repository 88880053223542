import React, { useState } from "react";
import OverlayModal from "../OverlayModal/OverlayModal";
import PlanCard from "./PlanCard";
import styles from "./PricingModal.module.css";

const unlimited_flex_europe = {
    id: 0,
    title: "Unlimited\nFlex",
    price: "14,95",
    currencySymbol: "€",
    duration: "monthly",
    description:
        "Full feature access, unlimited summaries, flashcards and Tutor AI messages on a flexible monthly basis.",
    price_id: "prc_unlimited-flex-6d3s010e",
};

const unlimited_semester_europe = {
    id: 1,
    title: "Unlimited\nSemester",
    price: "6,65",
    currencySymbol: "€",
    duration: "monthly\n for 6 months",
    description:
        "The best price for using Learnboost for a full semester. Unlimited summaries, flashcards and Tutor AI messages with all features included.",
    price_id: "prc_learnboost-unlimited-semester-f-r-6-65-monatlich-f-r-6-monate--jj4001vx",
};

const unlimited_flex_us = {
    id: 2,
    title: "Unlimited\nFlex",
    price: "14,95",
    currencySymbol: "$",
    duration: "monthly",
    description:
        "Full feature access, unlimited summaries, flashcards and Tutor AI messages on a flexible monthly basis.",
    price_id: "prc_learnboost-unlimited-flex-kpad0lim",
};

const unlimited_semester_us = {
    id: 3,
    title: "Unlimited\nSemester",
    price: "6,65",
    currencySymbol: "$",
    duration: "monthly\n for 6 months",
    description:
        "The best price for using Learnboost for a full semester. Unlimited summaries, flashcards and Tutor AI messages with all features included.",
    price_id: "prc_learnboost-unlimited-semester-9rae0l5d",
};

const BoxIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            style={{ width: "20px", height: "20px" }} // Inline style for width and height
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
        </svg>
    );
};

const plansEurope = [unlimited_flex_europe, unlimited_semester_europe];
const plansAmerica = [unlimited_flex_us, unlimited_semester_us];

const checkboxItems = [
    "Unlimited AI Summaries",
    "Unlimited Tutor AI Messages",
    "No Word or Page Limit",
    "Unlimited AI Flashcards",
    "Text Editor & Study Mode",
    "Access Upcoming Features first",
];

const AmericanCountries = ["AU", "CA", "US"];

function UpgradePlanModal({ isUpgradeModalOpen, toggleUpgradeModal, countryCode }) {
    const plans = AmericanCountries.includes(countryCode) ? plansAmerica : plansEurope;

    const returnValue = (
        <OverlayModal isVisible={isUpgradeModalOpen} onClose={toggleUpgradeModal} width="900px">
            <div className={styles.headline}>
                {" "}
                Upgrade Now:<br></br>
                <span className={styles.gradientText}>Save Unlimited Time and Effort</span>
            </div>
            <div className={styles.checkBoxes}>
                {checkboxItems.map((item, index) => (
                    <div key={index} className={styles.checkboxItem}>
                        <span className={styles.checkboxIcon}>
                            <BoxIcon />
                        </span>
                        <span className={styles.checkboxText}>{item}</span>
                    </div>
                ))}
            </div>
            <div className={styles.plans}>
                {plans.map((plan) => (
                    <PlanCard
                        key={plan.id}
                        isOpen={true}
                        closeOverlay={toggleUpgradeModal}
                        plan={plan}
                        currencySymbol={plan.currencySymbol}
                    />
                ))}
            </div>
        </OverlayModal>
    );

    return returnValue;
}

export default UpgradePlanModal;
