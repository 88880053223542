import posthog from "posthog-js";
import { config } from "./config";

const EVENTS = {
    // MODE-selection
    APP_MODE_CHANGED: "app-mode-changed",

    // general
    LANGUAGE_CHANGED: "user-language-changed",
    USER_LOGGED_OUT: "user-logged-out",
    USER_PROFILE_OPENED: "user-profile-opened",

    // Tutor
    TUTOR_CHAT_CLEARED: "tutor-chat-cleared",
    TUTOR_CHAT_SENT: "tutor-chat-sent",
    TOTOR_REPLY_COPIED: "tutor-reply-copied",
    TUTOR_REFLINK_CLICKED: "tutor-reflink-clicked",
    TUTOR_APP_MODE_CHANGED: "tutor-app-mode-changed",

    // Summary
    SUMMARY_GENERATED: "summary-generated",
    SUMMARY_DOWNLOAD_LINK_GENERATED: "summary-download-link-generated",
    SUMMARY_REFLINK_CLICKED: "summary-reflink-clicked",

    // Flashcards
    FLASHCARDS_GENERATED: "flashcards-generated",
    FLASHCARDS_REGENERATED: "flashcards-regenerated",
    FLASHCARDS_STUDYMODE_STARTED: "flashcards-study-mode-started",
    FLASHCARDS_COLLECTION_FINISHED: "flashcards-collection-finished",
    FLASHCARDS_STUDYMODE_STOPPED: "flashcards-study-mode-stopped",
    FLASHCARDS_STODYMODE_RESETTED: "flashcards-resetted",
    FLASHCARD_ANSWERED_CORRECTLY: "flashcard-answered-correctly",
    FLASHCARD_ANSWERED_INCORRECTLY: "flashcard-answered-incorrectly",
    FLASHCARDS_CARD_ADDED: "flashcards-card-added",
    FLASHCARDS_CARD_DELETED: "flashcards-card-deleted",
    FLASHCARDS_DOWNLOAD_LINK_GENERATED: "flashcards-download-link-generated",
    FLASHCARDS_REPETITIONS_CHANGED: "flashcards-repetitions-changed",

    // Feedback
    FEEDBACK_MODAL_OPENED: "feedback-modal-opened",
    FEEDBACK_MODAL_CLOSED: "feedback-modal-closed",
    FEEDBACK_SUBMITTED: "feedback-submitted",

    // Filemanager
    FILEMANAGER_OPENED: "filemanager-opened",
    FILEMANAGER_CLOSED: "filemanager-closed",
    FILEMANAGER_TRASH_CLEARED: "filemanager-trash-cleared",
    FILEMANAGER_SEARCHED: "filemanager-searched",
    FOLDER_CREATED: "folder-created",
    FOLDER_DELETED: "folder-deleted",

    // Documents
    DOCUMENT_OPENED: "document-opened",
    DOCUMENT_UPLOADED: "document-uploaded",
    DOCUMENT_DELETED: "document-deleted",

    // PRICING
    EASTEREGG_ACTIVATED: "easter-egg-activated",
    PRICING_MODAL_OPENED: "pricing-modal-opened",
    PRICING_MODAL_CLOSED: "pricing-modal-closed",
    PLAN_UPGRADE_CLICKED: "plan-upgrade-clicked",
};

const capture_event = (event_name, properties) => {
    //console.log("tracking event", event_name, properties);
    posthog.capture(event_name, properties);
};

const initialize_posthog = () => {
    const ENV = config.env;
    const POSTHOG_API_KEY = config.posthog_api_key;
    const POSTHOG_API_HOST = config.posthog_api_host;

    if (ENV === "production" || config.posthog_enabled === "true") {
        console.log("initializing analytics");
        posthog.init(POSTHOG_API_KEY, {
            api_host: POSTHOG_API_HOST,
            person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
        });
    }
};

export { capture_event, EVENTS, initialize_posthog };
